import React, {Component} from 'react';
import axios from 'axios';
import {apiURL, formatReportDate} from "../Utils";
import {Tab, Tabs} from "react-bootstrap";

export default class SurveyReportView extends Component {

    constructor() {
        super();
        this.state = {
            table1Rows: [],
            table2Rows: [],
            table3Rows: [],
            table3Headers: [],
            tabListKey: 'report1'
        }
    }

    componentDidMount() {
        // this.loadReport1Data();
    }   

    loadReport1Data() {
        axios.get(apiURL + '/report/districtSurveyReport?districtId=' + this.props.user.stateId + '&userType=' + this.props.user.role).then(result=>{
            if (result.data.statusCode === 200) {
                if (result.data.data.length > 0) {
                    let guys = 0, girls = 0, notIncluded = 0, studyType1 = 0, studyType2 = 0, studyType3 = 0;
                    let rows = result.data.data.map((row, index)=>{
                        guys = guys + Number(row.guys);
                        girls = girls + Number(row.girls);
                        notIncluded = notIncluded + Number(row.testResult10);
                        studyType1 = studyType1 + Number(row.testResult1);
                        studyType2 = studyType2 + Number(row.testResult2);
                        studyType3 = studyType3 + Number(row.testResult3);
                        return <tr key={"rep1_" + index}><td>{index+1}</td><td>{row.schoolName}</td><td>{row.guys + row.girls}</td>
                            <td>{row.guys}</td><td>{row.girls}</td><td>{row.testResult10}</td><td>{row.testResult1}</td><td>{row.testResult2}</td><td>{row.testResult3}</td></tr>
                    });
                    let summaryRow = <tr key={"rep1_last"}><td colSpan={2}><b>JAMI:</b></td><td><b>{guys + girls}</b></td>
                        <td><b>{guys}</b></td><td><b>{girls}</b></td><td><b>{notIncluded}</b></td><td><b>{studyType1}</b></td>
                        <td><b>{studyType2}</b></td><td><b>{studyType3}</b></td></tr>;
                    rows.push(summaryRow);
                    this.setState({
                        table1Rows: rows
                    })
                }
            }
        })
    }

    loadReport2Data() {
        axios.get(apiURL + '/report/districtSurveyReport2?districtId=' + this.props.user.stateId + '&userType=' + this.props.user.role).then(result=>{
            if (result.data.statusCode === 200) {
                if (result.data.data.length > 0) {
                    let pupilCount = 0, studyType1 = 0, studyType2 = 0, studyType3 = 0, studyType4 = 0;
                    let rows = result.data.data.map((row, index)=>{
                        pupilCount = pupilCount + Number(row.testResult15);
                        studyType1 = studyType1 + Number(row.testResult1);
                        studyType2 = studyType2 + Number(row.testResult2);
                        studyType3 = studyType3 + Number(row.testResult3);
                        studyType4 = studyType4 + Number(row.testResult4);
                        return <tr key={"rep2_" + index}><td>{index+1}</td><td>{row.schoolName}</td><td>{row.testResult15}</td>
                            <td>{row.testResult1}</td><td>{row.testResult2}</td><td>{row.testResult3}</td><td>{row.testResult4}</td></tr>
                    });
                    let summaryRow = <tr key={"rep2_last"}><td colSpan={2}><b>JAMI:</b></td><td><b>{pupilCount}</b></td>
                        <td><b>{studyType1}</b></td><td><b>{studyType2}</b></td><td><b>{studyType3}</b></td><td><b>{studyType4}</b></td></tr>;
                    rows.push(summaryRow);
                    this.setState({
                        table2Rows: rows
                    })
                }
            }
        })
    }

    loadReport3Data() {
        axios.get(apiURL + '/report/districtSurveyReport3?districtId=' + this.props.user.stateId + '&userType=' + this.props.user.role).then(result=>{
            if (result.data.statusCode === 200) {
                if (result.data.data.tendencies.length > 0) {
                    let rows = [];
                    let headers = [];
                    headers.push(<th>№</th>);
                    headers.push(<th>{this.props.user.role==='STATE' ? 'Tuman/shahar nomi' : 'Maktab raqami yoki nomi'}</th>);
                    headers.push(<th>Jami o‘quvchilar soni</th>);
                    for (let i=0; i<result.data.data.tendencies.length; i++) {
                        headers.push(<th>{result.data.data.tendencies[i].name}</th>);
                    }
                    /*let rows2 = [];
                    for (let i=0; i<result.data.data.items.length; i++) {
                        let row = result.data.data.items[i];
                        for (let j=0; j<row.counts.length; j++) {
                            rows.push(<td>{row.counts[j]}</td>);
                        }
                        rows.push(<tr><td>{row.id}</td><td>{row.name}</td>);
                        rows.unshift(<tr>);
                        rows.push(</tr>);
                    }*/
                    this.setState({
                        table3Rows: rows,
                        table3Headers: headers
                    })
                }
            }
        })
    }

    onSelectTab(tabName) {
        this.setState({tabListKey: tabName});
        if (tabName === 'report1') {
            this.loadReport1Data();
        } else if (tabName === 'report2') {
            this.loadReport2Data();
        } else if (tabName === 'report3') {
            this.loadReport3Data();
        }
    }

    render() {
        return(
            <div className="content  mt-1">
                <Tabs activeKey={this.state.tabListKey} onSelect={key => this.onSelectTab(key)}>
                    <Tab eventKey="report1" title="1-ilova">
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <span className="font-weight-bold font-22">{this.props.user.role==='ADMIN' ? 'Respublika' :
                                (this.props.user.role==='STATE' ? this.props.user.stateName : this.props.user.stateName + ' ' + this.props.user.districtName)}dagi umumta’lim maktablarining
                                    9-sinf o‘quvchilari o‘rtasida o‘tkazilgan so‘rovnoma natijalari to‘g‘risida ma’lumot
                                </span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 text-right">
                                <span className="mr-1">{formatReportDate()}</span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <table className="table table-bordered">
                                    <thead className="font-times">
                                    <tr key="header1">
                                        <th rowSpan={2}>№</th>
                                        <th rowSpan={2}>{this.props.user.role==='STATE' ? 'Tuman/shahar nomi' : 'Maktab raqami yoki nomi'}</th>
                                        <th rowSpan={2}>Jami o‘quvchilar soni</th>
                                        <th colSpan={2}>Shundan</th>
                                        <th rowSpan={2}>So‘rovnomada qatnashmadi</th>
                                        <th colSpan={3}>Shundan</th>
                                    </tr>
                                    <tr key="header2">
                                        <th>O‘g‘il</th>
                                        <th>Qiz</th>
                                        <th>Akademik litseyda</th>
                                        <th>Kasb-hunar maktabida</th>
                                        <th>10-sinfda</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.table1Rows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="report2" title="2-ilova">
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <span className="font-weight-bold font-22">{this.props.user.role==='ADMIN' ? 'Respublika' :
                                    (this.props.user.role==='STATE' ? this.props.user.stateName : this.props.user.stateName + ' ' + this.props.user.districtName)}dagi umumta’lim maktablarining
                                    9-sinf o‘quvchilari o‘rtasida o‘tkazilgan so‘rovnoma natijalari to‘g‘risida ma’lumot
                                </span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 text-right">
                                <span className="mr-1">{formatReportDate()}</span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <table className="table table-bordered">
                                    <thead className="font-times">
                                    <tr>
                                        <th>№</th>
                                        <th>{this.props.user.role==='STATE' ? 'Tuman/shahar nomi' : 'Maktab raqami yoki nomi'}</th>
                                        <th>Jami o‘quvchilar soni</th>
                                        <th>O‘z hududida</th>
                                        <th>Boshqa hududda</th>
                                        <th>Toshkent shahrida</th>
                                        <th>Respublikadan tashqarida</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.table2Rows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="report3" title="3-ilova">
                        <div className="form-row mt-2">
                            <div className="col-md-12 text-center">
                                <span className="font-weight-bold font-22">{this.props.user.role==='ADMIN' ? 'Respublika' :
                                    (this.props.user.role==='STATE' ? this.props.user.stateName : this.props.user.stateName + ' ' + this.props.user.districtName)}dagi umumta’lim maktablarining
                                    9-sinf o‘quvchilari o‘rtasida o‘tkazilgan so‘rovnoma natijalari to‘g‘risida ma’lumot
                                </span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 text-right">
                                <span className="mr-1">{formatReportDate()}</span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div style={{overflowX: "auto"}}>
                                    <table className="table table-bordered">
                                        <thead className="font-times">
                                        <tr>
                                            {this.state.table3Headers}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.table3Rows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        )
    }

}