import React, {Component} from "react";
import {Tab, Tabs} from "react-bootstrap";
import ReportContent from "../district/ReportContent";

export default class AdminPsychTestReportView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabListKey: 'klass7',
        }
    }

    render() {
        return(
            <div>
                <Tabs activeKey={this.state.tabListKey} onSelect={key => this.setState({tabListKey: key})}>
                    <Tab eventKey="klass5" title="5-sinf">
                        <ReportContent userType="ADMIN" klass="5"/>
                    </Tab>
                    <Tab eventKey="klass6" title="6-sinf">
                        <ReportContent userType="ADMIN" klass="6"/>
                    </Tab>
                    <Tab eventKey="klass7" title="7-sinf">
                        <ReportContent userType="ADMIN" klass="7"/>
                    </Tab>
                    <Tab eventKey="klass8" title="8-sinf">
                        <ReportContent userType="ADMIN" klass="8"/>
                    </Tab>
                    <Tab eventKey="klass9" title="9-sinf">
                        <ReportContent userType="ADMIN" klass="9"/>
                    </Tab>
                    <Tab eventKey="klass10" title="10-sinf">
                        <ReportContent userType="ADMIN" klass="10"/>
                    </Tab>
                    <Tab eventKey="klass11" title="11-sinf">
                        <ReportContent userType="ADMIN" klass="11"/>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}