import React, {Component} from 'react';
import axios from 'axios';
import {apiURL, strings} from "./Utils";
import {NavLink} from "react-router-dom";

class QuizSummaryView extends Component {

	constructor() {
		super();
        let minutes = window.localStorage.getItem('minutes');
		this.state = {
			questions: [],
			totalQuestions: 0,
			currentQuestion: 0,
			currentTestId: null,
			answersData: [],
            time: {m:0, s:0}, seconds: minutes * 60
        };
        this.timer = 0;
		this.loadTest = this.loadTest.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
	}

	componentDidMount() {
		this.loadTest();
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.startTimer();
	}

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
        if (Number(hours) < 10) {
            hours = '0' + hours;
        }

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        if (Number(minutes) < 10) {
            minutes = '0' + minutes;
        }

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        if (Number(seconds) < 10) {
            seconds = '0' + seconds;
        }

        if (Number(secs) === 0) {
            const container = document.querySelector('.quiz-container');
            const nextButton = document.querySelector('.next');
            const result = document.querySelector('.result');
            nextButton.textContent = 'Finish';
            this.checkTestResult();
            container.style.display = 'none';
            result.innerHTML = '<h1 class="final-score">' + strings.yourResult + '   </h1><div id="resultDiv" class="summary"></div>' +
                '<div id="qrCodeDiv" class="summary"></div>';
            return;
        }

        return {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds === 0) {
            clearInterval(this.timer);
        }
    }

	loadTest() {
		document.getElementById('testName').innerHTML = window.localStorage.getItem('testName');
		let testId =window.localStorage.getItem('testId');
		this.setState({
			currentTestId: testId
		});

		axios.get(apiURL + '/test/getTest2?methodId=' + testId + "&language=" + (this.props.language === 'uz' ? 1 : 2))
			.then(result=>{
				if (result.data.statusCode === 200) {
					let questions = result.data.data;
					let totalQuestions = questions.length;
					if (Number(testId) === 27 || Number(testId) === 29 || Number(testId) === 32) {
						let label = document.createElement("label");
						label.setAttribute("class", "option");

						let input = document.createElement("input");
						input.setAttribute("type", "radio");
						input.setAttribute("name", "option");
						input.setAttribute("value", "3");

						let span = document.createElement("span");
						span.setAttribute("class", "option3");

						label.appendChild(input);
						label.appendChild(span);

						document.getElementById('answerOptions').appendChild(label);
					} else if (Number(testId) === 33 || Number(testId) === 36 || Number(testId) === 37) {
						let label = document.createElement("label");
						label.setAttribute("class", "option");

						let input = document.createElement("input");
						input.setAttribute("type", "radio");
						input.setAttribute("name", "option");
						input.setAttribute("value", "3");

						let span = document.createElement("span");
						span.setAttribute("class", "option3");

						label.appendChild(input);
						label.appendChild(span);

						document.getElementById('answerOptions').appendChild(label);

						let label4 = document.createElement("label");
						label4.setAttribute("class", "option");

						let input4 = document.createElement("input");
						input4.setAttribute("type", "radio");
						input4.setAttribute("name", "option");
						input4.setAttribute("value", "4");

						let span4 = document.createElement("span");
						span4.setAttribute("class", "option4");

						label4.appendChild(input4);
						label4.appendChild(span4);

						document.getElementById('answerOptions').appendChild(label4);
					}
					this.setState({
						questions: questions,
						totalQuestions: totalQuestions
					});
                    let questionBalls = document.getElementById('questionBalls');
                    for (let i=0; i<totalQuestions; i++) {
                        let div = document.createElement("div");
                        div.setAttribute("class", "ball-div");
                        div.setAttribute("id", "qb_" + i);
                        questionBalls.appendChild(div);
                    }
                    document.getElementById('qb_0').style.backgroundColor = "green";
					this.generateQuestions(questions, this.state.currentQuestion);
				}
			});
	}

	generateQuestions(questions, index) {
		const questionEl = document.querySelector('.question');
		const option1 = document.querySelector('.option1');
		const option2 = document.querySelector('.option2');
		const question = questions[index];
		const option1Total = question.answers[0].id;
		const option2Total = question.answers[1].id;
		//Populate html elements
		questionEl.innerHTML = (index+1) + '. ' + (this.props.language === 'uz' ? question.questionUz : question.questionRu);
		option1.setAttribute('data-total', `${option1Total}`);
		option2.setAttribute('data-total', `${option2Total}`);
		option1.innerHTML = this.props.language === 'uz' ? question.answers[0].answerUz : question.answers[0].answerRu;
		option2.innerHTML = this.props.language === 'uz' ? question.answers[1].answerUz : question.answers[1].answerRu;

		if (Number(this.state.currentTestId) === 27 || Number(this.state.currentTestId) === 29 || Number(this.state.currentTestId) === 32) {
			const option3 = document.querySelector('.option3');
			const option3Total = question.answers[2].id;
			option3.setAttribute('data-total', `${option3Total}`);
			option3.innerHTML = ' ' + (this.props.language === 'uz' ? question.answers[2].answerUz : question.answers[2].answerRu);
		} else if (Number(this.state.currentTestId) === 33 || Number(this.state.currentTestId) === 36 || Number(this.state.currentTestId) === 37) {
			const option3 = document.querySelector('.option3');
			const option3Total = question.answers[2].id;
			option3.setAttribute('data-total', `${option3Total}`);
			option3.innerHTML = ' ' + (this.props.language === 'uz' ? question.answers[2].answerUz : question.answers[2].answerRu);

			const option4 = document.querySelector('.option4');
			const option4Total = question.answers[3].id;
			option4.setAttribute('data-total', `${option4Total}`);
			option4.innerHTML = ' ' + (this.props.language === 'uz' ? question.answers[3].answerUz : question.answers[3].answerRu);
		} else if (Number(this.state.currentTestId) === 39) {
			if (this.state.currentQuestion===20) {
				let label = document.createElement("label");
				label.setAttribute("class", "option");

				let input = document.createElement("input");
				input.setAttribute("type", "radio");
				input.setAttribute("name", "option");
				input.setAttribute("value", "3");

				let span = document.createElement("span");
				span.setAttribute("class", "option3");

				label.appendChild(input);
				label.appendChild(span);

				document.getElementById('answerOptions').appendChild(label);

				let label4 = document.createElement("label");
				label4.setAttribute("class", "option");

				let input4 = document.createElement("input");
				input4.setAttribute("type", "radio");
				input4.setAttribute("name", "option");
				input4.setAttribute("value", "4");

				let span4 = document.createElement("span");
				span4.setAttribute("class", "option4");

				label4.appendChild(input4);
				label4.appendChild(span4);

				document.getElementById('answerOptions').appendChild(label4);
			} else if (this.state.currentQuestion===70) {
				const option4 = document.querySelector('.option4');
				option4.remove();
			}
			if (this.state.currentQuestion>=20 && this.state.currentQuestion<=70) {
				const option3 = document.querySelector('.option3');
				const option3Total = question.answers[2].id;
				option3.setAttribute('data-total', `${option3Total}`);
				option3.innerHTML = ' ' + (this.props.language === 'uz' ? question.answers[2].answerUz : question.answers[2].answerRu);

				const option4 = document.querySelector('.option4');
				const option4Total = question.answers[3].id;
				option4.setAttribute('data-total', `${option4Total}`);
				option4.innerHTML = ' ' + (this.props.language === 'uz' ? question.answers[3].answerUz : question.answers[3].answerRu);
			}
		}
	}

	loadPreviousQuestion() {
		let currentQuestion = this.state.currentQuestion;
		this.setState({
			currentQuestion: currentQuestion-1
		});
        document.getElementById('qb_' + (this.state.currentQuestion)).style.backgroundColor = "#FFFFFF";
		this.generateQuestions(this.state.questions, currentQuestion - 1);
	}

	loadNextQuestion() {
		const container = document.querySelector('.quiz-container');
		const nextButton = document.querySelector('.next');
		const result = document.querySelector('.result');
		const selectedOption = document.querySelector('input[type="radio"]:checked');
		//Check if there is a radio input checked
		if (!selectedOption) {
			alert(strings.pleaseSelectAnswer);
			return;
		}
		//Get value of selected radio
		const answerScore = Number(selectedOption.nextElementSibling.getAttribute('data-total'));

		let answersData = this.state.answersData;
		answersData[this.state.currentQuestion] = answerScore;

		selectedOption.checked = false;
		if (this.state.currentQuestion === this.state.totalQuestions - 1) {
			nextButton.textContent = 'Finish';
			this.checkTestResult();
			container.style.display = 'none';
			result.innerHTML = '<h1 class="final-score">' + strings.yourResult + '   </h1><div id="resultDiv" class="summary"></div>' +
				'<div id="qrCodeDiv" class="summary"></div>';
			return;
		}
		this.setState({
			answersData: answersData,
			currentQuestion: this.state.currentQuestion + 1
		});
		this.generateQuestions(this.state.questions, this.state.currentQuestion + 1);
		document.getElementById('qb_' + (this.state.currentQuestion + 1)).style.backgroundColor = "green";
	}

	checkTestResult() {
		let testId = window.localStorage.getItem('testId');
		let pupilId = window.localStorage.getItem('pupilId');
		axios.get(apiURL + "/test/checkResult2?testMethodId=" + testId + "&pupilId=" + pupilId + "&language=" + (this.props.language === 'uz' ? 1 : 2) + "&testResult=" + this.state.answersData)
			.then(result=>{
				if (result.status === 200) {
					let summary = document.querySelector('.summary');
					if (Number(this.state.currentTestId) === 39) {
						summary.innerHTML = '<a href="' + result.data + '">' + strings.downloadResult + '</a>';
					} else {
						let resultDiv = document.getElementById('resultDiv');
						let b = document.createElement('b');
						b.innerHTML = result.data.message;
						resultDiv.appendChild(b);
                        let a = document.createElement('a');
                        a.href = result.data.data.data.pdfLink;
                        let link = document.createTextNode("Ko`chirib olish");
                        a.setAttribute('class', 'btn');
                        let i = document.createElement('i');
                        i.setAttribute('class', 'fa fa-download fa-lg');
                        a.appendChild(i);
                        a.appendChild(link);

                        let message = document.createTextNode(result.data.data.data.testResult);
                        resultDiv.appendChild(message);
                        resultDiv.appendChild(a);

                        if (result.data.data.data.qrCodeLink !== null && result.data.data.data.qrCodeLink !== '') {
                            let qrCodeDiv = document.getElementById('qrCodeDiv');
                            let qrCodeLink = document.createElement('img');
                            qrCodeLink.setAttribute('src', result.data.data.data.qrCodeLink);
                            qrCodeDiv.appendChild(qrCodeLink);
                        }
					}
				}
			});
	}

	render() {
		return (
			<section className="about-area inner-content-wrapper p-4">
				<div className="container">
					<div className="row">
						<div className="col-md-2">
							<div className="full-sec-content">
								<div className="card card-profile mt--300 shadow ml-box">
									<div className="px-4">
										<div className="mt-2 py-3 border-top text-center">
											<div className="row justify-content-center">
												<div className="col-lg-12">
													<div className="nav flex-column nav-pills"
														id="v-pills-tab" role="tablist"
														aria-orientation="vertical" >
														<NavLink className="nav-link active text-uppercase" to={"/quiz"}>
															<i className="fa fa-school fa-2x"/>
															<br/>
															{strings.klass7}
														</NavLink>
														<hr/>
														<NavLink className="nav-link text-uppercase" to={"/quiz"}>
															<i className="fa fa-school fa-2x"/>
															<br/>
															{strings.klass8}
														</NavLink>
														<hr/>
														<NavLink className="nav-link text-uppercase" to={"/quiz"}>
															<i className="fa fa-school fa-2x"/>
															<br/>
															{strings.klass9}
														</NavLink>
														<hr/>
														<NavLink className="nav-link text-uppercase" to={"/quiz"}>
															<i className="fa fa-school fa-2x"/>
															<br/>
															{strings.klass10}
														</NavLink>
														<hr/>
														<NavLink className="nav-link text-uppercase" to={"/quiz"}>
															<i className="fa fa-school fa-2x"/>
															<br/>
															{strings.klass11}
														</NavLink>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-10">
							<div className="full-sec-content">
								<div className="card card-profile mt--300 shadow ml-box">
									<div className="px-4">
										<div className="mt-2 py-3 border-top text-center">
											<div className="row justify-content-center">
												<div className="col-lg-12">
													<div className="quiz-container tx">
                                                        <div>
                                                            {this.state.time !== undefined ? this.state.time.m + ':' + this.state.time.s : ''}
                                                        </div>
														<div id="testName" className="title"/>
														<div id="questionBalls"/>
														<div id="question" className="question"/>
														<div id="answerOptions">
															<label className="option">
																<input type="radio" name="option" value="1"/>
																<span className="option1"/>
															</label>

															<label className="option">
																<input type="radio" name="option" value="2"/>
																<span className="option2"/>
															</label>
														</div>

														<div className="controls">
                                                            {this.state.currentQuestion > 0 &&
															<button className="btn previous"
																	onClick={this.loadPreviousQuestion.bind(this)}>{strings.previous}</button>
                                                            }
															<button className="btn next" onClick={this.loadNextQuestion.bind(this)}>{strings.next}</button>
														</div>
													</div>

													<div className="result"/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default QuizSummaryView;