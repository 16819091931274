import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import axios from 'axios';
import {apiURL} from "./Utils";
import {strings} from "./Utils";

class ProfessionView extends Component {

	componentDidMount() {
		this.getProfession();
	}

	getString(prefix, str, suffix) {
		return str === undefined || str === null || str === '' ? '' : prefix + str + suffix
	}

	getProfession() {
		let prefix = "<div class=\"card-body\"><div class=\"text-justify\"><p class=\"ml-4\"><i class=\"fa fa-list mr-2 p-2\" aria-hidden=\"true\"></i>";
		let suffix = "</p></div></div>";
		let urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');
		axios(apiURL + '/profession/item?id=' + id)
			.then(result=>{
				if (result.status === 200) {
					document.getElementById('kasbNomi').innerHTML = result.data.name;
					document.getElementById('professionName').innerHTML = result.data.name;
					document.getElementById('professionImage').src = 'images/' + result.data.image;
					document.getElementById('professionPresentation').innerHTML = this.getString(prefix, result.data.presentation, suffix);
					document.getElementById('professionType').innerHTML = this.getString(prefix, result.data.professionType, suffix);
					document.getElementById('professionContent').innerHTML = this.getString(prefix, result.data.content, suffix);
					document.getElementById('professionTerms').innerHTML = this.getString(prefix, result.data.terms, suffix);
					document.getElementById('professionRequirements').innerHTML = this.getString(prefix, result.data.requirements, suffix);
					document.getElementById('individualRequirements').innerHTML = this.getString(prefix, result.data.individualRequirements, suffix);
					document.getElementById('medicalBarrier').innerHTML = this.getString(prefix, result.data.medicalBarrier, suffix);
					document.getElementById('waysToGetJob').innerHTML = this.getString(prefix, result.data.waysToGetJob, suffix);
					document.getElementById('applicationArea').innerHTML = this.getString(prefix, result.data.applicationArea, suffix);
					document.getElementById('relatedProfessions').innerHTML = this.getString(prefix, result.data.relatedProfessions, suffix);
					document.getElementById('growthProspects').innerHTML = this.getString(prefix, result.data.growthProspects, suffix);
				}
			});
	}

	render() {
		return(
			<div>
				<section className="page-banner">
					<div className="image-layer" style={{backgroundImage: "url(images/bread-bg.jpg)"}}/>
					<div className="auto-container">
						<div className="breadcrumb-box">
							<div className="auto-container">
								<ul className="bread-crumb clearfix">
									<li><NavLink to="/">{strings.homePage}</NavLink></li>
									<li className="active">professionView</li>
								</ul>
							</div>
						</div>
						<span id="kasbNomi" style={{fontSize: "50px", fontWeight: "bold"}}/>
					</div>
				</section>

				<section className="services-sec service-details-area inner-content-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="service-details-item">
									<div className="service-details-img">
										<h2>
											<span id="professionName"/>
										</h2>

										<div className="container">
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className="full-sec-content">
														<div className="sec-title style-two">
															<div className="ml-edu">
																<h4>{strings.datas}</h4>
															</div>
															<span className="decor"><span className="inner"/></span>
														</div>
														<div className="card card-profile mt--300 shadow ml-box">
															<div className="px-4">
																<div className="text-center mt-3 mb-3 pt-100">
																	<img id="professionImage" src="" alt=""/>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="full-sec-content">
														<div className="sec-title style-two">
															<div className="ml-edu">
																<h4>{strings.sections}</h4>
															</div>
															<span className="decor"><span className="inner"/></span>
														</div>
														<div className="card card-profile mt--300 shadow ml-box">
															<div className="px-4">
																<div className="text-center mt-3 mb-3 pt-100">
																	<h4>{strings.workingContent}</h4>
																</div>
																<div className="mt-2 py-3 border-top text-center">
																	<div className="row justify-content-center">
																		<div className="col-lg-12">
																			<div id="accordion">
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.professionPresentation}
																						</h5>
																					</div>
																					<p id="professionPresentation"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.professionType}
																						</h5>
																					</div>
																					<p id="professionType"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.professionContent}
																						</h5>
																					</div>
																					<p id="professionContent"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.professionTerms}
																						</h5>
																					</div>
																					<p id="professionTerms"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header"
																					     id="headingTwo">
																						<h5 className="mb-0 d-flex">
																							{strings.professionRequirements}
																						</h5>
																					</div>
																					<p id="professionRequirements"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.individualRequirements}
																						</h5>
																					</div>
																					<p id="individualRequirements"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.medicalRestrictions}
																						</h5>
																					</div>
																					<p id="medicalBarrier"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.waysToGetProfession}
																						</h5>
																					</div>
																					<p id="waysToGetJob"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.scopeOfTheProfession}
																						</h5>
																					</div>
																					<p id="applicationArea"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.relatedProfessions}
																						</h5>
																					</div>
																					<p id="relatedProfessions"/>
																				</div>
																				<div className="card border-0 mb-2">
																					<div className="card-header">
																						<h5 className="mb-0 d-flex">
																							{strings.growthProspects}
																						</h5>
																					</div>
																					<p id="growthProspects"/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default ProfessionView;