import React, {Component} from 'react';
import * as _ from '../Utils';
import {strings} from '../Utils';
import axios from 'axios';
import TestItem from './TestItem';
import Swal from 'sweetalert2';
// import  { Redirect } from 'react-router-dom';

class Testing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            question: '',
            questions: [],
            questionCount: 0,
            questionNumber: 0,
            pupilsAnswers: [],
            selectedTest: {},
            psychologist: {},
            language: 1,
            tendencies: [],
            answers: [],
            fullAnswered: false,
            fullAnswer: '',
            toDownload: false,
            toHome: false,
            toTest: false,
            downloadLink: '',
	        toTesting: false,
	        redirectToHomePage: false,
            redirectToShortCoursesPage: false
        };
        this.onFinishClick = this.onFinishClick.bind(this);
    }

    componentDidMount() {
        let language = Number(localStorage.getItem('language'));
        strings.setLanguage(language === 2 ? "ru" : "uz");
        let complexTest = JSON.parse(localStorage.getItem('complexTest'));
        let selectedTest = complexTest[0];
        let psychologist = JSON.parse(localStorage.getItem('psychologist'));
        this.setState({
            language: language,
            selectedTest: selectedTest,
            psychologist: psychologist,
        })

        this.init(selectedTest, language);
    }

    init(selectedTest, language) {
        let questions = selectedTest.tests;
        let pupilsAnswers = [];
        for (let i = 0; i < questions.length; i++) {
            pupilsAnswers.push('');
        }

        let answers = [];
        if (Number(selectedTest.id) === 47) {
            answers.push({"id": 1, 'answer': language === 2 ? "Да" : "Ha"});
            answers.push({"id": 2, "answer": language === 2 ? "Нет" : "Yo`q"});
        } else {
            answers = questions[0].answers;
        }

        this.setState({
            fullAnswered: false,
            selectedTest: selectedTest,
            questions: questions,
            question: language === 2 ? questions[0].questionRu : questions[0].questionUz,
            questionCount: questions.length,
            questionNumber: 0,
            pupilsAnswers: pupilsAnswers,
            answers: answers
        });
    }

    toNextQuestion(pupilsAnswer) {
        let q_number = this.state.questionNumber < this.state.questions.length - 1 ? this.state.questionNumber + 1 : this.state.questionNumber;
        let pupilsAnswers = [...this.state.pupilsAnswers];
        pupilsAnswers[this.state.questionNumber] = pupilsAnswer;

        let question = this.state.language === 2 ? this.state.questions[q_number].questionRu : this.state.questions[q_number].questionUz;
        let answers = [];
        if (Number(this.state.selectedTest.id) === 47) {
            answers.push({"id": 1, 'answer': this.state.language === 2 ? "Да" : "Ha"});
            answers.push({"id": 2, "answer": this.state.language === 2 ? "Нет" : "Yo`q"});
        } else {
            answers = this.state.language === 2 ? this.state.questions[q_number].answers : this.state.questions[q_number].answers;
        }

        let unAnswered = 0;
        for (let i = 0; i < pupilsAnswers.length; i++) {
            if (pupilsAnswers[i] === '') {
                unAnswered = unAnswered + 1;
            }
        }
        this.setState({
            pupilsAnswers: pupilsAnswers,
            questionNumber: q_number,
            question: question,
            answers: answers,
            fullAnswered: unAnswered === 0
        });
    }

    onFinishClick(e) {
        let unAnswered = [];
        for (let i = 0; i < this.state.questionCount; i++) {
            if (this.state.pupilsAnswers[i] === undefined || this.state.pupilsAnswers[i] === null || this.state.pupilsAnswers[i] === '') {
                unAnswered.push(i + 1);
            }
        }

        if (unAnswered.length > 0) {
            Swal.fire('Siz quyidagi savollarga javob bermadingiz: ' + unAnswered, '', "warning");
        } else {
            document.getElementById('testFinishBtn').disabled = true;
            let result = this.state.pupilsAnswers.join('_');
            axios.post(_.apiURL + '/test/checkKGPsychologistTestResult?psychologistId=' + this.state.psychologist.id + '&testMethodId=' + this.state.selectedTest.id + '&testResult=' + result)
                .then(result => {
                    if (result.data.statusCode === 200) {
                        let selectedTestId = Number(this.state.selectedTest.id);
                        if (selectedTestId === 49) {
                            document.getElementById('testFinishBtn').disabled = false;
                        }
                        let selectedTest = {};
                        let complexTest = JSON.parse(localStorage.getItem('complexTest'));
                        if (selectedTestId === 49) {
                            selectedTest = complexTest[1];
                        } else if (selectedTestId === 47) {
                            selectedTest = complexTest[2];
                        }
                        if (selectedTestId === 50) {
                            Swal.fire({
                                title: this.state.language === 2 ? 'Результат теста' : 'Test natijasi',
                                text: this.state.language === 2 ? 'Результаты теста сохранены' : 'Test natijalari saqlandi',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                            }).then((result1) => {
                                    this.setState = {
                                        question: '',
                                        questions: [],
                                        questionCount: 0,
                                        questionNumber: 0,
                                        pupilsAnswers: [],
                                        selectedTest: {},
                                        psychologist: {}
                                    };
                                    localStorage.removeItem('psychologist');
                                    localStorage.removeItem('selectedTest');
                                    localStorage.removeItem('language');
                                    window.open("/mtttest", "_self");
                            });
                        } else {
                            this.init(selectedTest, this.state.language);
                        }
                    } else {
                        Swal.fire({
                            title: 'Test natijasi',
                            text: result.data.data,
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                        })
                    }
                });
        }
    }

    render() {
        let language = Number(localStorage.getItem('language'));
        return (
            <div style={{width: '95%', padding: '20px 300px'}}>
                {Number(this.state.selectedTest.id) === 47 &&
                    <>
                        {language === 1 &&
                            <>
                                <div><p style={{fontSize: "18px"}}><b>Yoʻriqnoma:</b></p></div>
                                <div style={{float: 'left', fontSize: "18px"}}>
                                    <p><i>Oʻzingizni sinab koʻring.</i> Siz odamlar bilan ishlash sohasida faoliyat yurituvchi
                                        mutaxassissiz. Siz uchun shaxsiy psixologik himoya mexanizmlaringiz qay darajada
                                        shakllanganini bilish qiziq boʻlishi mumkin. Bu himoya mexanizmlari ish jarayonida
                                        emotsional qoniqishni yoʻqotish va ruhiy charchash (emotsional soʻnish) koʻrinishida
                                        namoyon boʻlishi mumkin.
                                    </p>
                                    <p><i>Quyidagi fikrlarni oʻqing va “ha” yoki “yoʻq” deb javob bering.</i></p>
                                    <p>Shuni yodda tutingki, anketa matnida “hamkor” tushunchasi qoʻllangan hollarda, u
                                        sizning kasbiy faoliyatingizdagi subyektlarni – tarbiyalanuvchilar, ota-onalar, xodimlar,
                                        hamkasblar va har kuni muloqot qiladigan boshqa shaxslarni nazarda tutadi.</p>
                                </div>
                            </>
                        }
                        {language === 2 &&
                            <>
                                <div><p style={{fontSize: "18px"}}><b>Инструкция:</b></p></div>
                                <div style={{float: 'left', fontSize: "18px"}}>
                                    <p><i>Проверьте себя.</i> Вы являетесь профессионалом в сфере взаимодействии с людьми, Вам будет интересно увидеть,
                                        в какой степени у Вас сформировалась психологическая защита в форме эмоционального выгорания.
                                    </p>
                                    <p><i>Читайте суждения и отвечайте "да" или "нет".</i></p>
                                    <p>Примите во внимание, что, если в формулировках опросника идет речь о партнерах, то имеются в виду субъекты
                                        вашей профессиональной деятельности - воспитанники, родители, коллеги, руководство, учащиеся и другие люди, с которыми вы ежедневно работаете.</p>
                                </div>
                            </>
                        }
                    </>
                }

                <div className="d-flex w-100">
                    <div style={{width: '50%', float: 'left'}}><p style={{fontSize: "20px"}}><b>{strings.member}: {this.state.psychologist.name}</b></p></div>
                    <div style={{width: '50%', float: 'right'}}><p style={{fontSize: "20px"}}><b>{strings.method}: {this.state.selectedTest.name}</b></p></div>
                </div>
                <hr/>
                <div style={{width: '100%', marginTop: "20px"}}>
                    <TestItem questionOrder={this.state.questionNumber + 1} testId={+this.state.selectedTest.id}
                              question={this.state.question}
                              answers={this.state.answers} toNextQuestion={this.toNextQuestion.bind(this)}/>
                </div>

                {this.state.questionNumber === this.state.questions.length - 1 &&
                <div style={{width: '100%', padding: '20px'}}>
                    <button id='testFinishBtn' className="btn btn-success" onClick={this.onFinishClick}>
                        {(Number(this.state.selectedTest.id) === 49 || Number(this.state.selectedTest.id) === 47) ? "Keyingisi" : strings.finish}</button>
                </div>
                }
            </div>
        )
    }
}

export default Testing;