import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import {apiURL} from "./Utils";
import {strings} from "./Utils";

class ContactView extends Component {

	submitMessage() {
		let name = document.getElementById('name').value;
		let email = document.getElementById('email').value;
		let subject = document.getElementById('msg_subject').value;
		let message = document.getElementById('message').value;
		if (name === null || name === '' || email === null || email === '' || subject === null || subject === '' || message === null || message === '') {
			swal("Hamma ma'lumotlarni kiriting", '', 'error');
			return;
		}
		let formData = new FormData();
		formData.append('name', name);
		formData.append('email', email);
		formData.append('subject', subject);
		formData.append('message', message);
		axios.post(apiURL + '/profession/sendMessage', formData)
			.then(result=>{
				if (result.status === 200) {
					swal('Xabaringiz yuborildi', '', 'success');
				} else {
					swal('Xabaringiz yuborilmadi', '', 'error');
				}
			})
	}

	render() {
		return (
			<div>
				<section className="page-banner">
					<div className="image-layer" style={{backgroundImage: "url(images/bread-bg.jpg)"}}/>
					<div className="auto-container">
						<div className="breadcrumb-box">
							<div className="auto-container">
								<ul className="bread-crumb clearfix">
									<li><NavLink to="/">{strings.homePage}</NavLink></li>
									<li className="active">{strings.contact}</li>
								</ul>
							</div>
						</div>
						<h1>{strings.contact}</h1>
					</div>
				</section>

				<section className="contact-area inner-content-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								<div className="contact-form">
									<div className="title">
										<h3>{strings.sendMessage}</h3>
									</div>
									<form id="contactForm">
										<div className="row">
											<div className="col-lg-6 col-md-12">
												<div className="form-group">
													<label>{strings.fullName}</label>
													<input
														type="text"
														name="name"
														id="name"
														className="form-control"
														required
														data-error="Familiyangizni kiriting"
													/>
													<div className="help-block with-errors"/>
												</div>
											</div>
											<div className="col-lg-6 col-md-12">
												<div className="form-group">
													<label>Email</label>
													<input type="email" name="email" id="email" className="form-control"
													       required
													       data-error="Emailingizni kiriting"/>
													<div className="help-block with-errors"/>
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>{strings.subject}</label>
													<input
														type="text"
														name="msg_subject"
														id="msg_subject"
														className="form-control"
														required
														data-error="Mavzuni kiriting"
													/>
													<div className="help-block with-errors"/>
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>{strings.messageContent}</label>
													<textarea
														name="message"
														className="form-control"
														id="message"
														cols="30"
														rows="5"
														required
														data-error="Xabaringizni yozing"
													/>
													<div className="help-block with-errors"/>
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<button type="button" className="btn mt-3" onClick={this.submitMessage.bind(this)}>
													{strings.sendMessage}
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="contact-side-box">
									<div className="title">
										<h3>{strings.contactDetails}</h3>
									</div>
									<div className="info-box">
										<div className="icon"><i className="fa fa-clock"/></div>
										<h4>{strings.workingHours}</h4>
										<ul className="list">
											<li>
												Dush – Shan
												<span>09:00 - 18:00</span>
											</li>
											<li>
												Yakshanba
												<span>Dam olish kuni</span>
											</li>
										</ul>
									</div>
									<div className="info-box">
										<div className="icon"><i className="fa fa-map-marker"/></div>
										<h4>{strings.address}</h4>
										<span>{strings.ourAddress}</span>
									</div>
									<div className="info-box">
										<div className="icon"><i className="fa fa-phone"/></div>
										<h4>{strings.phoneNumber}</h4>
										<span><a href="tel:+998 (71) 246-98-04">+998 (71) 246-98-04</a></span>
									</div>
									<div className="info-box">
										<div className="icon"><i className="fa fa-envelope-o"/></div>
										<h4>Email</h4>
										<span><a href="mailto:tashxis_markazi@xtv.uz">tashxis_markazi@xtv.uz </a></span>
										<span><a href="https://t.me/tashxismarkazi">Telegram: t.me/tashxismarkazi</a></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="yandex-map center shadow">
					<iframe title="map"
						src="https://yandex.com/map-widget/v1/?um=constructor%3Aadde1ead86c5e64585b9b0669682b4ed72afa9a422188ad2b03652c71886e53e&amp;source=constructor"
						width="100%"
						height="700"
						frameBorder="0"
					/>
				</div>
			</div>
		)
	}
}

export default ContactView;