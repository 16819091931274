import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import swal from 'sweetalert';
import {strings} from "../Utils";

export default class Class11PupilSurveyView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            survey: {
                studyType: {
                    id: 0
                },
                institutionType: {
                    id: 0
                },
                institutionTendency: '',
                institutionAdminType: {
                    id: 0
                },
                choiceType: {
                    id: 0
                },
                workType: {
                    id: 0
                },
                goal: {
                    id: 0
                },
                customWork: '',
                customScience: '',
                infoType: {
                    id: 0
                },
                factor: {
                    id: 0
                },
                interestedSciences: []
            },
            pupil: props.pupil,
            sciences: [
                strings.subject_1,strings.subject_2,strings.subject_3,strings.subject_4,
                strings.subject_5,strings.subject_6,strings.subject_7,strings.subject_8,
                strings.subject_9,strings.subject_10,strings.subject_11,strings.subject_12,
                strings.subject_13,strings.subject_14,strings.subject_15,strings.subject_16,strings.subject_17
            ]
        }
    }

    componentDidMount() {
        axios.get(_.apiURL + '/pupil/survey/item?surveyId=' + this.props.survey.id)
            .then(result => {
                if (result.data.statusCode === 200) {
                    this.setState({
                        survey: result.data.data
                    })
                }
            });
    }

    propertyChange(e) {
        let name = e.target.id;
        let value = name.includes('sc_') ? e.target.checked : e.target.value;
        if (name.includes('sc_')) {
            let sel = 0;
            let interestedSciences = this.state.survey.interestedSciences;
            if (!value) {
                let interestedSciences2 = [];
                interestedSciences.map(val => {
                    if (val !== name) {
                        interestedSciences2.push(val);
                        this.setState({
                            survey: {
                                ...this.state.survey,
                                interestedSciences: interestedSciences2
                            }
                        });
                    } else {
                        sel += 1;
                    }
                });
                interestedSciences = interestedSciences2;
            }
            if (interestedSciences.length < 4) {
                if (sel === 0) {
                    interestedSciences.push(name);
                    this.setState({
                        survey: {
                            ...this.state.survey,
                            interestedSciences: interestedSciences
                        }
                    });
                }
            } else {
                document.getElementById(name).checked = false;
            }
        } else {
            if (name === 'institutionTendency' || name === 'customWork' || name === 'customScience') {
                this.setState({
                    survey: {
                        ...this.state.survey,
                        [name]: value
                    }
                });
            } else {
                this.setState({
                    survey: {
                        ...this.state.survey,
                        [name]: {
                            id: value
                        }
                    }
                });
            }
        }
    }

    save11Survey() {
        let errors = 0;

        let studyType = this.state.survey.studyType.id;
        errors = _.validateSelect('studyType', errors);

        let institutionTendency = '';
        if (Number(studyType) === 1) {
            institutionTendency = this.state.survey.institutionTendency;
            errors = _.validateField('institutionTendency', institutionTendency, errors);
        }

        let institutionType = this.state.survey.institutionType.id;
        errors = _.validateSelect('institutionType', errors);

        let institutionAdminType = this.state.survey.institutionAdminType.id;
        errors = _.validateSelect('institutionAdminType', errors);

        let choiceType = this.state.survey.choiceType.id;
        errors = _.validateSelect('choiceType', errors);

        let workType = this.state.survey.workType.id;
        errors = _.validateSelect('workType', errors);

        let customWork = this.state.survey.customWork;
        errors = _.validateField('workType', workType, errors);

        let goal = this.state.survey.goal.id;
        errors = _.validateSelect('goal', errors);

        if (this.state.survey.interestedSciences.length === 0) {
            document.getElementById('scienceWidgets').style.boxShadow = "0px 0px 5px 5px red";
            errors++;
        }

        let customScience = this.state.survey.customScience;
        if (document.getElementById('sc_17').checked) {
            errors = _.validateField('customScience', customScience, errors);
        }

        let infoType = this.state.survey.infoType.id;
        errors = _.validateSelect('infoType', errors);

        let factor = this.state.survey.factor.id;
        errors = _.validateSelect('factor', errors);

        if (errors === 0) {
            let formData = new FormData();
            formData.append("klass", 11);
            formData.append("pupilId", this.state.pupil.id);
            formData.append("studyType", studyType);
            formData.append("institutionTendency", institutionTendency);
            formData.append("institutionType", institutionType);
            formData.append("institutionAdminType", institutionAdminType);
            formData.append("choiceType", choiceType);
            formData.append("workType", workType);
            formData.append("customWork", customWork);
            formData.append("goal", goal);
            formData.append("interestedSciences", this.state.survey.interestedSciences);
            formData.append("customScience", customScience);
            formData.append("infoType", infoType);
            formData.append("factor", factor);

            formData.append("vocationalSchoolTendency", '');
            formData.append("class10ChooseReason", 0);
            formData.append("lyceumTendency", 0);
            axios.post(_.apiURL + '/pupil/saveSurvey', formData)
                .then(result => {
                    if (result.data.statusCode === 200) {
                        this.props.reloadList();
                        swal(result.data.message, '', 'success');
                        this.props.onClose();
                    } else {
                        swal(result.data.message, '', 'error')
                    }
                })
        } else {
            swal(strings.enterAllInformation, '', 'error');
        }
    }

    render() {
        let scienceWidgets = this.state.sciences.map((el, index)=>{
            index++;
            let sc_selected = false;
            if (this.state.survey !== null && this.state.survey.interestedSciences !== null) {
                for (let i = 0; i < this.state.survey.interestedSciences.length; i++) {
                    if (this.state.survey.interestedSciences[i] === "sc_" + index) {
                        sc_selected = true;
                    }
                }
            }
            return <div className="col-md-3" key={"div_sc_" + index}>
                <input type="checkbox" id={"sc_" + index} key={"sc_" + index} name={"sc_" + index}
                       checked={sc_selected}
                       onChange={this.propertyChange.bind(this)}/>
                <label className="m-lg-1" htmlFor={"sc_" + index}>{el}</label>
            </div>
        });
        return (
            <div style={{padding: "2rem"}}>
                <div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4><b>{strings.pupil}: {this.state.pupil.name}</b></h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="studyType"><b>{strings.afterClass11}</b></label>
                            <select id="studyType" className="form-control" onChange={this.propertyChange.bind(this)}
                                    value={this.state.survey.studyType !== null ? this.state.survey.studyType.id : 0}>
                                <option value="0">{strings.pleaseSelect}</option>
                                <option value="1">{strings.class10Goal_1}</option>
                                <option value="2">{strings.class10Goal_2}</option>
                                <option value="3">{strings.class10Goal_3}</option>
                                <option value="4">{strings.class10Goal_4}</option>
                                <option value="5">{strings.class10Goal_5}</option>
                            </select>
                        </div>
                    </div>
                    {Number(this.state.survey.studyType.id) === 1 &&
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="institutionTendency">{strings.whichTendency}</label>
                                <select id="institutionTendency" className="form-control" value={this.state.survey.institutionTendency}
                                onChange={this.propertyChange.bind(this)}>
                                    <option value="0">{strings.pleaseSelect}</option>
                                    <option value="1">{strings.study}</option>
                                    <option value="2">{strings.artsAndHumanities}</option>
                                    <option value="3">{strings.socialSciencesJournalism}</option>
                                    <option value="4">{strings.businessManagementLaw}</option>
                                    <option value="5">{strings.naturalSciencesMathematicsStatistics}</option>
                                    <option value="6">{strings.ict}</option>
                                    <option value="7">{strings.engineeringMachiningConstruction}</option>
                                    <option value="8">{strings.agricultureForestry}</option>
                                    <option value="9">{strings.healthCareSocialSecurity}</option>
                                    <option value="10">{strings.services}</option>
                                </select>
                            </div>
                        </div>
                    }
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="institutionType"><b>{strings.whereHigherEducation}</b></label>
                        <select id="institutionType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.institutionType !== null ? this.state.survey.institutionType.id : 0}>
                            <option value="0">{strings.pleaseSelect}</option>
                            <option value="1">{strings.foreign}</option>
                            <option value="2">{strings.foreignFilial}</option>
                            <option value="3">{strings.heiRepublic}</option>
                            <option value="4">{strings.heiRegional}</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="institutionAdminType"><b>{strings.institutionType}</b></label>
                        <select id="institutionAdminType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.institutionAdminType !== null ? this.state.survey.institutionAdminType.id : 0}>
                            <option value="0">{strings.pleaseSelect}</option>
                            <option value="1">{strings.gowInst}</option>
                            <option value="2">{strings.nonGowInst}</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="choiceType"><b>{strings.whoHelped}</b></label>
                        <select id="choiceType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.choiceType !== null ? this.state.survey.choiceType.id : 0}>
                            <option value="0">{strings.pleaseSelect}</option>
                            <option value="1">{strings.whoHelped_1}</option>
                            <option value="2">{strings.whoHelped_2}</option>
                            <option value="3">{strings.whoHelped_3}</option>
                            <option value="4">{strings.whoHelped_4}</option>
                            <option value="5">{strings.whoHelped_5}</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="workType">{strings.whatYouWantToWork}</label>
                        <select id="workType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.workType !== null ? this.state.survey.workType.id : 0}>
                            <option value="0">{strings.pleaseSelect}</option>
                            <option value="1">{strings.industry}</option>
                            <option value="2">{strings.technique}</option>
                            <option value="3">{strings.construction}</option>
                            <option value="4">{strings.agriculture}</option>
                            <option value="5">{strings.production}</option>
                            <option value="6">{strings.service}</option>
                            <option value="7">{strings.art}</option>
                            <option value="8">{strings.study}</option>
                            <option value="9">{strings.ict}</option>
                            <option value="10">{strings.healthCare}</option>
                            <option value="11">{strings.military}</option>
                            <option value="12">{strings.finance}</option>
                            <option value="13">{strings.other}</option>
                        </select>
                    </div>
                </div>
                {Number(this.state.survey.workType) === 13 &&
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="customWork">{strings.otherDirection}</label>
                        <input id="customWork" className="form-control" value={this.state.survey.customWork}/>
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="goal"><b>{strings.mainGoal}</b></label>
                        <select id="goal" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.goal !== null ? this.state.survey.goal.id : 0}>
                            <option value="0">{strings.pleaseSelect}</option>
                            <option value="1">{strings.mainGoal_1}</option>
                            <option value="2">{strings.mainGoal_2}</option>
                            <option value="3">{strings.mainGoal_3}</option>
                            <option value="4">{strings.mainGoal_4}</option>
                            <option value="5">{strings.mainGoal_5}</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <span><b>{strings.whatSubjectsInterestYou}</b></span>
                    </div>
                </div>
                <div className="row" id="scienceWidgets">
                    {scienceWidgets}
                </div>
                {Number(this.state.survey.studyType.id) === 1 &&
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="customScience"><b>{strings.otherSubjects}</b></label>
                        <input id="customScience" className="form-control" value={this.state.survey.customScience}/>
                    </div>
                </div>
                }

                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="infoType"><b>{strings.fromWhichSource}</b></label>
                        <select id="infoType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.infoType !== null ? this.state.survey.infoType.id : 0}>
                            <option value="0">{strings.pleaseSelect}</option>
                            <option value="1">{strings.fromWhichSource_1}</option>
                            <option value="2">{strings.fromWhichSource_2}</option>
                            <option value="3">{strings.fromWhichSource_3}</option>
                            <option value="4">{strings.fromWhichSource_4}</option>
                            <option value="5">{strings.fromWhichSource_5}</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="factor"><b>{strings.importantFactor}</b></label>
                        <select id="factor" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.factor !== null ? this.state.survey.factor.id : 0}>
                            <option value="0">{strings.pleaseSelect}</option>
                            <option value="1">{strings.importantFactor_1}</option>
                            <option value="2">{strings.importantFactor_2}</option>
                            <option value="3">{strings.importantFactor_3}</option>
                            <option value="4">{strings.importantFactor_4}</option>
                            <option value="5">{strings.importantFactor_5}</option>
                            <option value="6">{strings.importantFactor_6}</option>
                            <option value="7">{strings.importantFactor_7}</option>
                            <option value="8">{strings.importantFactor_8}</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-success" onClick={this.save11Survey.bind(this)}>{strings.save}</button>
                    </div>
                </div>
            </div>
        )
    }
}