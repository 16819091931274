import React from 'react';
import {createRoot} from 'react-dom/client';
import './css/animate.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

createRoot(document.getElementById('root'))
	.render(
	<React.StrictMode>
		<BrowserRouter>
			<App/>
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
