import React, {Component} from 'react';
import axios from 'axios';
import {Tab, Tabs} from "react-bootstrap";
import {apiURL, getRegions, logout} from "./Utils";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import 'react-responsive-modal/styles.css';
// import './css/loading.sass';
import ReactPaginate from 'react-paginate';
import ReportContent from "./district/ReportContent";

class MinistryHomeView extends Component {

	constructor() {
		super();
        let user = JSON.parse(localStorage.getItem('user'));
		this.state = {
            user: user,
			tabListKey: 'reports',
			tabListKey2: 'klass7',
			tableRows: [],
			schoolTableRows: [],
			regionId: "",
			districtId: "",
			schoolId: "",
			klass: "",
			methodId: "",
			startDate: "",
			endDate: "",

			districts: [],
			schools: [],

			regionId2: "",
			districtId2: "",
			districts2: [],
			schools2: [],
			start: 0,
			offset: 100,
            perPage: 100,
            showLoading: false,
            pageCount: 1,
		};
		this.setValue = this.setValue.bind(this);
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			[name]: [value]
		})
	}

	loadData(regionId, districtId, schoolId, klass, methodId, startDate, endDate) {
		this.setState({
            showLoading: true
		});
		axios.get(apiURL + '/report/testResultReport?regionId=' + regionId + '&districtId=' + districtId + '&schoolId=' + schoolId +
			'&klass=' + klass + '&methodId=' + methodId + '&startDate=' + startDate + '&endDate=' + endDate + '&start=' + this.state.start + '&offset=' + this.state.offset)
			.then(result=>{
				if (result.data.statusCode === 200) {
					if (result.data.data.data !== undefined && result.data.data.data !== null && result.data.data.data.length > 0) {
						let rows = result.data.data.data.map((row, index)=>{
							return <tr>
								<td>{index+1}</td>
								<td>{row.stateName}</td>
								<td>{row.districtName}</td>
								<td>{row.schoolName}</td>
								<td>{row.klassName}</td>
								<td>{row.lastName}</td>
								<td>{row.firstName}</td>
								<td>{row.methodName}</td>
								<td>{row.testResult}</td>
							</tr>
						});
						this.setState({
							tableRows: rows,
                            pageCount: result.data.data.pageCount,
                            showLoading: false
						})
					} else {
						this.setState({
							tableRows: [],
                            pageCount: 1,
                            showLoading: false
						})
					}
				} else {
					this.setState({
						tableRows: [],
                        pageCount: 1,
                        showLoading: false
					})
				}
			})
	}

	getSchools(regionId, districtId) {
		axios.get(apiURL + '/pupil/school/list2?regionId=' + regionId + '&districtId=' + districtId)
			.then(result=>{
				if (result.data.statusCode === 200) {
					if (result.data.data !== undefined && result.data.data !== null && result.data.data.length > 0) {
						let rows = result.data.data.map((row, index)=>{
							return <tr>
								<td>{index+1}</td>
								<td>{row.stateName}</td>
								<td>{row.districtName}</td>
								<td>{row.schoolName}</td>
								<td>{row.userName}</td>
								<td>{row.password}</td>
								<td><button className="btn btn-success" onClick={this.openModal.bind(this, row)}>Tahrirlash</button></td>
							</tr>
						});
						this.setState({
							schoolTableRows: rows
						})
					} else {
						this.setState({
							schoolTableRows: []
						})
					}
				} else {
					this.setState({
						schoolTableRows: []
					})
				}
			})
	}

	loadDistricts(relatedTo) {
		let regions = relatedTo === 'report' ? document.getElementById('regions') : document.getElementById('regions2');
		axios.get(apiURL + "/pupil/districts?regionId=" + regions.value + '&language=1')
			.then(result=>{
				if (result.data.statusCode === 200) {
					let districts = result.data.data.map(data=> {
						return <option value={data.id}>{data.name}</option>;
					});
					districts.unshift(<option value="">Tanlang</option>);
					if (relatedTo === 'report') {
						this.setState({
							districts: districts,
							schools: "",
						})
					} else {
						this.setState({
							districts2: districts,
						})
					}
				}
			});
		if (relatedTo === 'report') {
			this.setState({
				regionId: regions.value
			});
			this.loadData(regions.value, "", "", this.state.klass, this.state.methodId, this.state.startDate, this.state.endDate);
		} else {
			this.setState({
				regionId2: regions.value
			});
			this.getSchools(regions.value, "");
		}
	}

	loadSchools(relatedTo) {
		let districts = relatedTo === 'report' ? document.getElementById('districts') : document.getElementById('districts2');
		axios.get(apiURL + "/pupil/schools?districtId=" + districts.value)
			.then(result=>{
				if (result.data.statusCode === 200) {
					let schools = result.data.data.map(data=> {
						return <option value={data.id}>{data.name}</option>;
					});
					schools.unshift(<option value="">Tanlang</option>);
					if (relatedTo === 'report') {
						this.setState({
							schools: schools
						})
					} else {
						this.setState({
							schools2: schools
						})
					}
				}
			});
		if (relatedTo === 'report') {
			this.setState({
				districtId: districts.value
			});
			this.loadData("", districts.value, "", this.state.klass, this.state.methodId, this.state.startDate, this.state.endDate);
		} else {
			this.setState({
				districtId2: districts.value
			});
			this.getSchools(this.state.regionId2, districts.value);
		}
	}

	onChangeSchool() {
		let schoolId = document.getElementById('schools').value;
		this.setState({
			schoolId: schoolId
		});
		this.loadData("", "", schoolId, this.state.klass, this.state.methodId, this.state.startDate, this.state.endDate);
	}

	onChangeKlass() {
		let klass = document.getElementById('klass').value;
		this.setState({
			klass: klass
		});
		this.loadData("", "", this.state.schoolId, klass, this.state.methodId, this.state.startDate, this.state.endDate);
	}

	onChangeMethod() {
		let method = document.getElementById('method').value;
		this.setState({
			methodId: method
		});
		this.loadData("", "", this.state.schoolId, this.state.klass, method, this.state.startDate, this.state.endDate);
	}

	setStartDate(startDate) {
		this.setState({
			startDate: startDate
		});
		if (startDate !== undefined && startDate !== null && startDate !== '' && this.state.endDate !== '') {
			this.loadData(this.state.regionId, this.state.districtId, this.state.schoolId, this.state.klass, this.state.methodId, startDate.getTime(), this.state.endDate.getTime());
		}
	}

	setEndDate(endDate) {
		this.setState({
			endDate: endDate
		});
		if (endDate !== undefined && endDate !== null && endDate !== '' && this.state.startDate !== '') {
			this.loadData(this.state.regionId, this.state.districtId, this.state.schoolId, this.state.klass, this.state.methodId, this.state.startDate.getTime(), endDate.getTime());
		}
	}

    handlePageClick = (data) => {
        let selected = data.selected;
        let start = selected === 0 ? 0 : selected * this.state.perPage + 1;

        this.setState({
            start: start
        });
        this.loadData(this.state.regionId, this.state.districtId, this.state.schoolId, this.state.klass, this.state.methodId, this.state.startDate, this.state.endDate);
    };

	render() {
		let regions = getRegions('uz');
		return (
			<div className="card card-body">
                {this.state.showLoading &&
				<div className="container">
					<div className="loader">
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--text"/>
					</div>
				</div>
                }
				<div className="text-right" style={{position: "absolute", right: "0"}}>
					<span className="p-3"><b>{this.state.user.lastName + ' ' + this.state.user.firstName}</b></span>
					<span className="cursor-pointer p-3 btn-info" onClick={logout}>
						<i className="fa fa-sign-out"/>&nbsp;Chiqish
					</span>
				</div>
				<Tabs id="controlled-tab-example" activeKey={this.state.tabListKey} onSelect={key => this.setState({tabListKey: key})}>
					<Tab eventKey="reports" title="Hisobotlar">
						<div className="form-row mt-5">
							<div className="col-md-12 mb-3 text-center">
								<h3>Psixologik test natijalari</h3>
							</div>
						</div>
						<div className="form-row mb-3">
							<div className="col-md-2">
								<select id="regions" className="form-control" onChange={this.loadDistricts.bind(this, 'report')}>
									{regions}
								</select>
							</div>
							<div className="col-md-2">
								<select id="districts" className="form-control" onChange={this.loadSchools.bind(this, 'report')}>
									{this.state.districts}
								</select>
							</div>
							<div className="col-md-2">
								<select id="schools" className="form-control" onChange={this.onChangeSchool.bind(this)}>
									{this.state.schools}
								</select>
							</div>
							<div className="col-md-1">
								<select id="klass" className="form-control" onChange={this.onChangeKlass.bind(this)}>
									<option value="">Tanlang</option>
									<option value="7">7-sinf</option>
									<option value="8">8-sinf</option>
									<option value="9">9-sinf</option>
									<option value="10">10-sinf</option>
									<option value="11">11-sinf</option>
								</select>
							</div>
							<div className="col-md-1">
								<select id="method" className="form-control" onChange={this.onChangeMethod.bind(this)}>
									<option value="0">Tanlang</option>
									<option value="26">Maqsadga intilish testi</option>
									<option value="27">Tashkilotchilik qobiliyatini aniqlash testi</option>
									<option value="28">Kasb tiplarini aniqlash metodikasi</option>
									<option value="29">Muloqоtchanlikning darajasini aniqlash testi</option>
									<option value="30">O`quvchilarning qiziqishlarini aniqlash so`rovnomasi</option>
									<option value="31">O`quvchilarning qiziqishlari, qobiliyatlari va intilishlarini aniqlash metodikasi</option>
									<option value="32">Shaxs yo`nalganligini aniqlash metodikasi</option>
									<option value="34">Kasb tanlash motivini aniqlash</option>
									<option value="35">Qiziqishlar xaritasi metodikasi</option>
									<option value="36">O`quv motivatsiyasining yo`nalganligini diagnostika qilish metodikasi</option>
									<option value="37">O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash bo`yicha test</option>
									<option value="38">Kasbiy moyillik so`rovnomasi</option>
								</select>
							</div>
							<div className="col-md-1">
								<DatePicker id="startDate" className="form-control" dateFormat="dd.MM.yyyy" selected={this.state.startDate} onChange={date => this.setStartDate(date)} />
							</div>
							<div className="col-md-1">
								<DatePicker id="endDate" className="form-control" dateFormat="dd.MM.yyyy"  selected={this.state.endDate} onChange={date => this.setEndDate(date)} />
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12 mb-3">
								<table className="table table-responsive">
									<thead>
										<tr>
											<th>#</th>
											<th width="10%">Hudud</th>
											<th width="10%">Tuman</th>
											<th width="10%">Maktab</th>
											<th width="5%">Sinf</th>
											<th width="10%">Familiyasi</th>
											<th width="10%">Ismi</th>
											<th width="15%">Metodika</th>
											<th width="30%">Natija</th>
										</tr>
									</thead>
									<tbody>
										{this.state.tableRows}
									</tbody>
								</table>
							</div>
							<div className="col-md-12 order-md-1 text-center pt-2">
								<ReactPaginate
									previousLabel={'Oldingi'}
									nextLabel={'Keyingi'}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={this.state.pageCount}
									marginPagesDisplayed={1}
									pageRangeDisplayed={5}
									onPageChange={this.handlePageClick}
									containerClassName={'pagination'}
									subContainerClassName={'pagination'}
									activeClassName={'active'}
								/>
							</div>
						</div>
					</Tab>
					<Tab eventKey="psychTestResults" title="Psixologik test natijalari">
						<Tabs activeKey={this.state.tabListKey2} onSelect={key => this.setState({tabListKey2: key})}>
							<Tab eventKey="klass7" title="7-sinf">
								<ReportContent userType="ADMIN" klass="7"/>
							</Tab>
							<Tab eventKey="klass8" title="8-sinf">
								<ReportContent userType="ADMIN" klass="8"/>
							</Tab>
							<Tab eventKey="klass9" title="9-sinf">
								<ReportContent userType="ADMIN" klass="9"/>
							</Tab>
							<Tab eventKey="klass10" title="10-sinf">
								<ReportContent userType="ADMIN" klass="10"/>
							</Tab>
							<Tab eventKey="klass11" title="11-sinf">
								<ReportContent userType="ADMIN" klass="11"/>
							</Tab>
						</Tabs>
					</Tab>
				</Tabs>
			</div>
		)
	}
}

export default MinistryHomeView;