import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {Tab, Tabs} from "react-bootstrap";
import {apiURL, logout, redirectToLogin, strings} from "../Utils";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import ReactPaginate from 'react-paginate';
import uz from 'date-fns/locale/uz';
import Cookies from 'universal-cookie';
import SchoolPupilSurveyList from "./SchoolPupilSurveyList";
import SchoolPupilList from "./SchoolPupilList";

const cookies = new Cookies();

registerLocale('uz', uz);
setDefaultLocale('uz');

class SchoolHomePage extends Component {

    constructor() {
        super();
        let user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            showLoading: false,
            user: user,
            tabListKey: user.testUser ? 'surveyList11' : 'pupilList',
            klass: '',
            methodId: '',
            startDate: new Date(),
            endDate: new Date(),

            pageCount: 1,
            perPage: 50,
            data: [],
            offset: 50,
            start: 0,
            showPdf: false
        };
        localStorage.setItem('sf', 0);
    }

    onChangeKlass(e) {
        let klass = e.target.value;
        let showPdf = false;
        if (klass !== '') {
            showPdf = true
        }
        this.setState({
            showPdf: showPdf,
            klass: klass
        });

        this.loadData(this.state.user.stateId, klass, this.state.methodId, this.state.startDate.getTime(), this.state.endDate.getTime(), this.state.start, this.state.offset);
    }

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        });
        if (startDate !== undefined && startDate !== null && startDate !== '' && this.state.endDate !== '') {
            this.loadData(this.state.user.stateId, this.state.klass, this.state.methodId, startDate.getTime(), this.state.endDate.getTime(), this.state.start, this.state.offset);
        }
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        });
        if (endDate !== undefined && endDate !== null && endDate !== '' && this.state.startDate !== '') {
            this.loadData(this.state.user.stateId, this.state.klass, this.state.methodId, this.state.startDate.getTime(), endDate.getTime(), this.state.start, this.state.offset);
        }
    }

    exportToExcel(formName) {
        let schoolId = this.state.user.stateId;
        let klass = this.state.klass;
        let methodId = this.state.methodId;
        if (formName === 'pdfForm') {
            if (klass === null || klass === '') {
                swal('Sinfni tanlang', '', 'error');
                return;
            }
            if (methodId === null || methodId === '') {
                swal('Metodikani tanlang', '', 'error');
                return;
            }
        }
        let startDate = '';
        let endDate = '';
        if (this.state.startDate !== undefined && this.state.startDate !== null && this.state.startDate !== '' &&
            this.state.endDate !== undefined && this.state.endDate !== null && this.state.endDate !== '') {
            startDate = this.state.startDate.getTime();
            endDate = this.state.endDate.getTime();
        }
        cookies.set('schoolId', schoolId, {path: '/'});
        cookies.set('klass', klass, {path: '/'});
        cookies.set('methodId', methodId, {path: '/'});
        cookies.set('startDate', startDate, {path: '/'});
        cookies.set('endDate', endDate, {path: '/'});
        let form = document.getElementById(formName);
        form.submit();
    }

    onChangeMethod() {
        let method = document.getElementById('method').value;
        this.setState({
            methodId: method
        });
        this.loadData(this.state.user.stateId, this.state.klass, method, this.state.startDate.getTime(), this.state.endDate.getTime(), this.state.start, this.state.offset);
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil((selected + 1) * this.state.perPage);
        let start = selected === 0 ? 0 : selected * this.state.perPage + 1;

        this.setState({
            offset: offset,
            start: start
        });
        this.loadData(this.state.user.stateId, this.state.klass, this.state.methodId, this.state.startDate.getTime(), this.state.endDate.getTime(), start, offset);
    };

    loadData(schoolId, klass, methodId, startDate, endDate, start, offset) {
        this.setState({
            showLoading: true
        });
        axios.get(apiURL + '/report/testResultReport?schoolId=' + schoolId +
            '&klass=' + klass + '&methodId=' + methodId + '&startDate=' + startDate + '&endDate=' + endDate + '&start=' + start + '&offset=' + offset)
            .then(result=>{
                if (result.data.statusCode === 200) {
                    if (result.data.data !== undefined && result.data.data !== null && result.data.data.data.length > 0) {
                        let rows = result.data.data.data.map((row, index)=>{
                            return <tr key={'trr_' + index}>
                                <td>{index+1}</td>
                                <td>{row.klassName}</td>
                                <td>{row.lastName}</td>
                                <td>{row.firstName}</td>
                                <td>{row.methodName}</td>
                                <td>{row.testResult}</td>
                            </tr>
                        });
                        this.setState({
                            tableRows: rows,
                            pageCount: result.data.data.pageCount,
                            showLoading: false
                        })
                    } else {
                        this.setState({
                            pageCount: 1,
                            tableRows: [],
                            showLoading: false
                        })
                    }
                } else {
                    this.setState({
                        pageCount: 1,
                        tableRows: [],
                        showLoading: false
                    })
                }
            })
    }

    reportTabSelect() {
        if (!this.state.user.testUser) {
            let start = new Date(this.state.startDate.setMonth(this.state.startDate.getMonth() - 6));
            this.setState({
                startDate: start
            });
            this.loadData(this.state.user.stateId, this.state.klass, this.state.methodId, start.getTime(), this.state.endDate.getTime(), this.state.start, this.state.offset);
        }
    }

    logout() {
        logout();
    }

    render() {
        if (this.state.user.role !== 'SCHOOL') {
            redirectToLogin();
        }
        return (
            <div style={{width: "100%", height: "100vh", minHeight: "100vh"}} className=" mt-2 ml-1 mr-1">
                {this.state.user !== undefined && this.state.user !== null && this.state.user.role === 'SCHOOL' &&
                    <div>
                        <div className="text-right mt-2" style={{position: "absolute", right: "0"}}>
                            <span className="p-3"><b>{this.state.user.stateName + ' ' + this.state.user.districtName + ' ' + this.state.user.schoolName}</b></span>
                            {(this.state.user.role === "SCHOOL") &&
                                <span className="cursor-pointer p-3" onClick={logout}>
                                <i className="fa fa-sign-out"/>&nbsp;{strings.logout}
                            </span>
                            }
                        </div>
                        <Tabs activeKey={this.state.tabListKey} animation="true" onSelect={key => this.setState({tabListKey: key})}>
                            {!this.state.user.testUser &&
                                <Tab eventKey="pupilList" title={strings.pupilList} mountOnEnter={true} unmountOnExit={true}>
                                    <SchoolPupilList user={this.state.user}/>
                                </Tab>
                            }
                            <Tab eventKey="surveyList9" title={strings.test9} mountOnEnter={true} unmountOnExit={true}>
                                <SchoolPupilSurveyList user={this.state.user} klass={9}/>
                            </Tab>
                            <Tab eventKey="surveyList11" title={strings.test11} mountOnEnter={true} unmountOnExit={true}>
                                <SchoolPupilSurveyList user={this.state.user} klass={11}/>
                            </Tab>
                            {!this.state.user.testUser &&
                                <Tab eventKey="testResults" title={strings.testResults} mountOnEnter={true} unmountOnExit={true}
                                     onSelect={this.reportTabSelect.bind(this)}>
                                    {/*{this.state.showLoading &&
                                        <div className="container">
                                            <div className="loader">
                                                <div className="loader--dot"/>
                                                <div className="loader--dot"/>
                                                <div className="loader--dot"/>
                                                <div className="loader--dot"/>
                                                <div className="loader--dot"/>
                                                <div className="loader--dot"/>
                                                <div className="loader--text"/>
                                            </div>
                                        </div>
                                    }*/}
                                    <div className="row mt-5">
                                        <div className="col-md-12 mb-3 text-center">
                                            <h3>{strings.psychTestResults}</h3>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <select id="klass" className="form-control"
                                                    onChange={this.onChangeKlass.bind(this)}>
                                                <option value="">{strings.pleaseSelect}</option>
                                                <option value="7">{strings.klass7}</option>
                                                <option value="8">{strings.klass8}</option>
                                                <option value="9">{strings.klass9}</option>
                                                <option value="10">{strings.klass10}</option>
                                                <option value="11">{strings.klass11}</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <select id="method" className="form-control"
                                                    onChange={this.onChangeMethod.bind(this)}>
                                                <option value="0">{strings.pleaseSelect}</option>
                                                <option value="26">Maqsadga intilish testi</option>
                                                <option value="27">Tashkilotchilik qobiliyatini aniqlash testi</option>
                                                <option value="28">Kasb tiplarini aniqlash metodikasi</option>
                                                <option value="29">Muloqоtchanlikning darajasini aniqlash testi</option>
                                                <option value="30">O`quvchilarning qiziqishlarini aniqlash so`rovnomasi
                                                </option>
                                                <option value="31">O`quvchilarning qiziqishlari, qobiliyatlari va
                                                    intilishlarini aniqlash metodikasi
                                                </option>
                                                <option value="32">Shaxs yo`nalganligini aniqlash metodikasi</option>
                                                <option value="34">Kasb tanlash motivini aniqlash</option>
                                                <option value="35">Qiziqishlar xaritasi metodikasi</option>
                                                <option value="36">O`quv motivatsiyasining yo`nalganligini diagnostika
                                                    qilish metodikasi
                                                </option>
                                                <option value="37">O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash
                                                    bo`yicha test
                                                </option>
                                                <option value="38">Kasbiy moyillik so`rovnomasi</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <DatePicker id="startDate" className="form-control" locale="uz"
                                                        dateFormat="dd.MM.yyyy" isClearable
                                                        placeholderText={strings.selectDate} selected={this.state.startDate}
                                                        onChange={date => this.setStartDate(date)}/>
                                        </div>
                                        <div className="col-md-2">
                                            <DatePicker id="endDate" className="form-control" locale="uz"
                                                        dateFormat="dd.MM.yyyy" isClearable
                                                        placeholderText={strings.selectDate} selected={this.state.endDate}
                                                        onChange={date => this.setEndDate(date)}/>
                                        </div>
                                        <div className="col-md-4 text-right">
                                            <div className="row">
                                                <div className="col-md-10 text-right">
                                                    <form id="excelForm3" method="post"
                                                          action={apiURL + "/common/pupilTestResultExcel"}>
                                                        <button className="btn btn-success"
                                                                onClick={this.exportToExcel.bind(this, 'excelForm3')}
                                                                title={strings.exportToExcel}><i
                                                            className="fa fa-file-excel-o"/></button>
                                                    </form>
                                                </div>
                                                <div className="col-md-2">
                                                    {this.state.showPdf &&
                                                        <form id="pdfForm" method="post"
                                                              action={apiURL + "/common/pupilsTestProtocolPdf"}>
                                                            <button className="btn btn-info"
                                                                    onClick={this.exportToExcel.bind(this, 'pdfForm')}
                                                                    title={strings.exportToPDF}><i
                                                                className="fa fa-file-pdf-o"/></button>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Sinf</th>
                                                    <th>Familiyasi</th>
                                                    <th>Ismi</th>
                                                    <th>Metodika</th>
                                                    <th>Natija</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.tableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 order-md-1 text-center">
                                            <ReactPaginate
                                                previousLabel={strings.previousText}
                                                nextLabel={strings.nextText}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    </div>
                                </Tab>
                            }
                        </Tabs>
                    </div>
                }
            </div>
        )
    }
}

export default SchoolHomePage;