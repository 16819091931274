import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {logout} from "./Utils";

export default class AdminMenu extends Component {
    render() {
        let user = localStorage.getItem('user');
        if (user !== null) {
            user = JSON.parse(user);
        }
        return (
            <div className="navbar-area">
                <div className="top-nav main-nav">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto">
                                <li className="nav-item">
                                    <Link to="/schools">Maktablar</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#">
                                        Hisobotlar
                                        <i className="bx bx-chevron-down"/>
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/psychTestReports" className="nav-link">
                                                Psixologik test natijalari
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/adminReports" className="nav-link">
                                                So`rovnoma hisoboti
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/tests" className="nav-link">
                                        Testlar
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/helpList">Psixologik maslahat</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/infoList">Infografikalar</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#">
                                        Muvaffaqqiyatli
                                        <i className="bx bx-chevron-down"/>
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/effPupils" className="nav-link">
                                                O`quvchilar
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/effPeoples" className="nav-link">
                                                Insonlar
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <span className="p-3"><b>{user.lastName + ' ' + user.firstName}</b></span>
                                    <span className="cursor-pointer p-3 btn-info" onClick={logout}>
                                        <i className="fa fa-sign-out"/>&nbsp;Chiqish
                                    </span>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }
}