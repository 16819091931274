import React from "react";
import axios from "axios";
import swal from "sweetalert";
import {apiURL} from "../Utils";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";

export default class TestListView extends React.Component {

    constructor() {
        super();
        this.state = {
            tests: [],
            test: {
                id: null,
                questionUz: '',
                questionRu: '',
                ball: 0
            },
            methods: [],
            method: {
                id: 0
            },
            modalOpen: false
        };
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount() {
        this.loadMethods();
    }

    loadMethods() {
        axios.get(apiURL + "/test/method/list")
            .then(result=>{
                if (result.data.statusCode === 200) {
                    let methods = result.data.data.map(data=> {
                        return <option value={data.id}>{data.name}</option>;
                    });
                    methods.unshift(<option value="0">Tanlang</option>);
                    this.setState({
                        methods: methods
                    })
                }
            });
    }

    changeMethod(e) {
        axios.get(apiURL + "/test/list?methodId=" + e.target.value)
            .then(result=>{
                if (result.data.statusCode === 200) {
                    let tests = result.data.data.map(data=> {
                        return <tr><td>{data.id}</td><td>{data.questionUz}</td><td>{data.questionRu}</td><td>{data.ball}</td>
                            <td><button className="btn btn-info" onClick={this.openModal.bind(this, data)}><i className="fa fa-edit"/></button></td>
                        </tr>;
                    });
                    this.setState({
                        tests: tests
                    })
                }
            });

        this.setState({
            method: {
                id: e.target.value
            }
        })
    }

    openModal(test) {
        this.setState({
            test: test !== null ? test :
                {
                    id: null,
                    questionUz: '',
                    questionRu: '',
                    ball: 0
                },
            modalOpen: true
        })
    }

    closeModal() {
        this.setState({
            test: {
                id: null,
                questionUz: '',
                questionRu: ''
            },
            modalOpen: false
        })
    }

    setValue(e) {
        this.setState({
            test: {
                ...this.state.test,
                [e.target.id]: e.target.value
            }
        })
    }

    saveTest() {
        axios.post(apiURL + '/test/update', this.state.test).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success')
            } else {
                swal(result.data.message, '', 'error')
            }
        })
    }

    render() {
        return(
            <div>
                <div className="form-row mt-3">
                    <div className="col-md-12 mb-3 text-center">
                        <h3>Testlar ro`yxati</h3>
                    </div>
                </div>
                <div className="form-row mb-3">
                    <div className="col-md-2">
                        <select id="methods" className="form-control" onChange={this.changeMethod.bind(this)}>
                            {this.state.methods}
                        </select>
                    </div>
                    <div className="col-md-2">
                        {this.state.method.id > 0 &&
                            <button className="btn-info" onClick={this.openModal.bind(this, null)}>Test qo`shish</button>
                        }
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <table className="table table-responsive w-100">
                            <thead>
                            <tr>
                                <th width="5%">#</th>
                                <th>Savol(uz)</th>
                                <th>Savol(ru)</th>
                                <th width="5%">Ball</th>
                                <th width="5%">Amal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.tests}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center>
                    <div style={{margin: "30px 0 0 0 "}}>
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="questionUz">Savol(uz)</label>
                                <textarea id="questionUz" className="form-control" defaultValue={this.state.test.questionUz} onChange={this.setValue}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label htmlFor="questionRu">Savol(ru)</label>
                                <textarea id="questionRu" className="form-control" defaultValue={this.state.test.questionRu} onChange={this.setValue}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label htmlFor="ball">Ball</label>
                                <input id="ball" className="form-control" defaultValue={this.state.test.ball} onChange={this.setValue}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-info" onClick={this.saveTest.bind(this)}>Saqlash</button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}