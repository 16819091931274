import React, {Component} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {logout, redirectToLogin, strings} from "../Utils";
// import '../css/loading.sass';
import ReportContent from "./ReportContent";
import SchoolReport1View from "../school/SurveyReportView";

export default class DistrictHomeView extends Component {

    constructor() {
        super();
        let user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            tabListKey: 'klass7',
            user: user
        }
    }

    render() {
        if (this.state.user.role !== 'DISTRICT') {
            redirectToLogin();
        }
        return (
            <div style={{width: "100%", height: "100vh", minHeight: "100vh"}}>
                {this.state.user !== undefined && this.state.user !== null && this.state.user.role === 'DISTRICT' &&
                <div className="mt-1">
                    <div className="text-right" style={{position: "absolute", right: "0"}}>
                        <span className="p-3"><b>{this.state.user.stateName + ' ' + this.state.user.districtName}</b></span>
                        <button className="btn-success" onClick={logout}>
                            <i className="fa fa-sign-out"/>&nbsp;Chiqish
                        </button>
                    </div>
                    <Tabs activeKey={this.state.tabListKey} onSelect={key => this.setState({tabListKey: key})}>
                        <Tab eventKey="klass7" title={strings.klass7}>
                            <ReportContent userType="DISTRICT" klass="7"/>
                        </Tab>
                        <Tab eventKey="klass8" title={strings.klass8}>
                            <ReportContent userType="DISTRICT" klass="8"/>
                        </Tab>
                        <Tab eventKey="klass9" title={strings.klass9}>
                            <ReportContent userType="DISTRICT" klass="9"/>
                        </Tab>
                        <Tab eventKey="klass10" title={strings.klass10}>
                            <ReportContent userType="DISTRICT" klass="10"/>
                        </Tab>
                        <Tab eventKey="klass11" title={strings.klass11}>
                            <ReportContent userType="DISTRICT" klass="11"/>
                        </Tab>
                        <Tab eventKey="reports" title={strings.surveyReports}>
                            <SchoolReport1View user={this.state.user}/>
                        </Tab>
                    </Tabs>
                </div>
                }
            </div>
        )
    }
}