export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {
            headers: {
                'Accept-Language': user.language,
                'Authorization': 'Bearer ' + user.token,
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true'
            }
        };
    } else {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': 'uz',
                'Access-Control-Allow-Origin': "http://localhost:3000",
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE,OPTIONS,CONNECT,TRACE'
            }
        };
    }
}