import React from "react";
import axios from "axios";
import {apiURL, getRegions, strings} from "../Utils";
import Modal from "react-responsive-modal";
import swal from "sweetalert";
import Swal from "sweetalert2";

export default class SchoolListView extends React.Component {

    constructor() {
        super();
        this.state = {
            school: {
                id: null,
                schoolName: '',
                userName: '',
                password: ''
            },
            districtId: null,
            regionId: null,
            editModal: false,
            moveModal: false,
            addModal: false,
            removeModal: false,
            schools: [],
            districts: [],
            districts2: [],
            regions: [],
            regions2: []
        }
        this.setValue = this.setValue.bind(this);
    }

    setValue(e) {
        this.setState({
            school: {
                ...this.state.school,
                [e.target.id]: e.target.value
            }
        })
    }

    openModal(school, modalName) {
        this.setState({
            school: school,
            [modalName]: true
        })
    }

    loadDistricts(relatedTo) {
        let regions = document.getElementById('regions');
        axios.get(apiURL + "/pupil/districts?regionId=" + regions.value + '&language=1')
            .then(result=>{
                if (result.data.statusCode === 200) {
                    let districts = result.data.data.map(data=> {
                        return <option value={data.id}>{data.name}</option>;
                    });
                    districts.unshift(<option value="0">Tanlang</option>);
                    if (relatedTo === 'report') {
                        this.setState({
                            districts: districts,
                            schools: [],
                        })
                    } else {
                        this.setState({
                            districts: districts,
                        })
                    }
                }
            });
        if (relatedTo === 'report') {
            this.setState({
                regionId: regions.value
            });
            this.loadData(regions.value, "", "", this.state.klass, this.state.methodId, this.state.startDate, this.state.endDate);
        } else {
            this.setState({
                regionId2: regions.value
            });
            this.getSchools(regions.value, "");
        }
    }

    getSchools(regionId, districtId) {
        axios.get(apiURL + '/pupil/school/list2?regionId=' + regionId + '&districtId=' + districtId)
            .then(result=>{
                if (result.data.statusCode === 200) {
                    if (result.data.data !== undefined && result.data.data !== null) {
                        let rows = result.data.data.map((row, index)=>{
                            return <tr>
                                <td>{index+1}</td>
                                <td>{row.stateName}</td>
                                <td>{row.districtName}</td>
                                <td>{row.schoolName}</td>
                                <td>{row.userName}</td>
                                <td>{row.password}</td>
                                <td className="d-flex">
                                    <button className="btn btn-success" onClick={this.openModal.bind(this, row, 'editModal')} title="Tahrirlash"><i className="fa fa-edit"/></button>
                                    <button className="btn btn-info ml-1" onClick={this.openModal.bind(this, row, 'moveModal')} title="Ko`chirish"><i className="fa fa-refresh"/></button>
                                    <button className="btn btn-danger ml-1" onClick={this.openModal.bind(this, row, 'removeModal')} title="O`chirish"><i className="fa fa-remove"/></button>
                                </td>
                            </tr>
                        });
                        this.setState({
                            schools: rows
                        })
                    } else {
                        this.setState({
                            schools: []
                        })
                    }
                } else {
                    this.setState({
                        schools: []
                    })
                }
            })
    }

    loadSchools(relatedTo) {
        let districts = relatedTo === 'report' ? document.getElementById('districts') : document.getElementById('districts2');
        axios.get(apiURL + "/pupil/schools?districtId=" + districts.value)
            .then(result=>{
                if (result.data.statusCode === 200) {
                    let schools = result.data.data.map(data=> {
                        return <option value={data.id}>{data.name}</option>;
                    });
                    schools.unshift(<option value="">Tanlang</option>);
                    this.setState({
                        schools: schools
                    })
                }
            });

        this.setState({
            districtId: districts.value
        });
        this.getSchools(relatedTo === 'report' ? this.state.regionId : this.state.regionId2, districts.value);
    }

    loadDistricts2() {
        let regionId = document.getElementById('moveRegion').value;
        axios.get(apiURL + "/pupil/districts?regionId=" + regionId + '&language=1')
            .then(result => {
                if (result.data.statusCode === 200) {
                    let districts = result.data.data.map(data => {
                        return <option value={data.id}>{data.name}</option>;
                    });
                    districts.unshift(<option value="0">Tanlang</option>);
                    this.setState({
                        districts2: districts
                    })
                }
            });
    }

    moveSchool() {
        let districtId = document.getElementById('moveDistrict').value;
        axios.post(apiURL + "/school/move?schoolId=" + this.state.school.id + '&districtId=' + districtId)
            .then(result => {
                if (result.data.statusCode === 200) {
                    swal(result.data.message, '', 'success');
                    this.closeModal();
                } else {
                    swal(result.data.message, '', 'error');
                }
            });
    }

    removeSchool() {
        Swal.fire({
            title: strings.removeSchool,
            text: strings.youSureRemove,
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: strings.yes,
            cancelButtonText: strings.no
        }).then((result) => {
            if (result.value) {
                axios.post(apiURL + "/school/delete/" + this.state.school.id)
                    .then(result => {
                        if (result.data.statusCode === 200) {
                            swal(result.data.message, '', 'success');
                            this.closeModal();
                        } else {
                            swal(result.data.message, '', 'error');
                        }
                    });
            }
        })
    }

    openAddSchoolModal() {
        let districtId = document.getElementById('districts2').value;
        axios.get(apiURL + '/school/generateLoginPass?districtId=' + districtId).then(result=>{
            if (result.data.statusCode === 200) {
                this.setState({
                    districtId: districtId,
                    school: {
                        ...this.state.school,
                        userName: result.data.data.userName,
                        password: result.data.data.password
                    },
                    addModal: true
                })
            }
        });
    }

    closeModal() {
        this.setState({
            school: {
                id: null,
                schoolName: '',
                password: ''
            },
            addModal: false,
            editModal: false,
            moveModal: false,
            removeModal: false
        })
    }

    changeSchoolData() {
        let schoolName = document.getElementById('schoolName').value;
        let password = document.getElementById('password').value;
        axios.post(apiURL + '/school/edit?schoolId=' + this.state.school.id + '&name=' + schoolName + '&password=' + password)
            .then(result=>{
                if (result.data.statusCode === 200) {
                    swal(result.data.message, '', 'success');
                } else {
                    swal(result.data.message, '', 'error');
                }
            })
    }

    addSchool() {
        if (this.state.school.schoolName === '') {
            swal('Maktab nomini kiriting', '', 'error');
            return;
        }
        if (this.state.school.userName === '') {
            swal('Maktab loginini kiriting', '', 'error');
            return;
        }
        if (this.state.school.password === '') {
            swal('Maktab parolini kiriting', '', 'error');
            return;
        }
        let data = new FormData();
        data.append('districtId', Number(this.state.districtId));
        data.append('name', document.getElementById('schoolName').value);
        data.append('userName', this.state.school.userName);
        data.append('password', this.state.school.password);
        axios.post(apiURL + '/school/add', data).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
                this.closeModal();
            } else {
                swal(result.data.message, '', 'success');
            }
        })
    }

    render() {
        let regions = getRegions('uz');
        return (
            <div>
                <div className="form-row mt-3">
                    <div className="col-md-12 mb-3 text-center">
                        <h3>Maktablar ro`yxati</h3>
                    </div>
                </div>
                <div className="form-row mb-3">
                    <div className="col-md-2">
                        <select id="regions" className="form-control" onChange={this.loadDistricts.bind(this, 'schools')}>
                            {regions}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select id="districts2" className="form-control" onChange={this.loadSchools.bind(this, 'schools')}>
                            {this.state.districts}
                        </select>
                    </div>
                    <div className="col-md-2">
                        {this.state.districtId !== null &&
                            <button className="btn-info" onClick={this.openAddSchoolModal.bind(this)}>Maktab
                                qo`shish</button>
                        }
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <table className="table table-responsive">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th width="25%">Hudud</th>
                                <th width="25%">Tuman</th>
                                <th width="25%">Maktab</th>
                                <th width="10%">Login</th>
                                <th width="10%">Parol</th>
                                <th width="5%">Amal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.schools}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Modal open={this.state.editModal} onClose={this.closeModal.bind(this)} center>
                    <div className="modal-margin">
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="schoolName">Maktab nomi</label>
                                <input id="schoolName" className="form-control" defaultValue={this.state.schoolName}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label htmlFor="password">Parol</label>
                                <input id="password" className="form-control" defaultValue={this.state.password}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-info" onClick={this.changeSchoolData.bind(this)}>Saqlash</button>
                        </div>
                    </div>
                </Modal>

                <Modal open={this.state.moveModal} onClose={this.closeModal.bind(this)} center>
                    <div className="modal-margin">
                        <div className="row">
                            <div className="col-md-12 text-center mb-3">
                                <h5><b>Maktabni boshqa hududga ko`chirish</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <label htmlFor="schoolName">Maktab:</label>
                            </div>
                            <div className="col-md-8 mb-2">
                                <p><b>{this.state.school.stateName + ' ' + this.state.school.districtName + ' ' + this.state.school.schoolName}</b></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <label htmlFor="moveRegion">Hudud:</label>
                            </div>
                            <div className="col-md-8 mb-2" onChange={this.loadDistricts2.bind(this)}>
                                <select id="moveRegion" className="form-control">
                                    {regions}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <label htmlFor="moveRegion">Tuman(shahar):</label>
                            </div>
                            <div className="col-md-8 mb-2">
                                <select id="moveDistrict" className="form-control">
                                    {this.state.districts2}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-info" onClick={this.moveSchool.bind(this)}>Saqlash</button>
                        </div>
                    </div>
                </Modal>

                <Modal open={this.state.removeModal} onClose={this.closeModal.bind(this)} center>
                    <div className="modal-margin">
                        <div className="row">
                            <div className="col-md-12 text-center mb-3">
                                <h5><b>Maktab ma'lumotlarini o`chirish</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <label htmlFor="schoolName">Maktab:</label>
                            </div>
                            <div className="col-md-10">
                                <p><b>{this.state.school.stateName + ' ' + this.state.school.districtName + ' ' + this.state.school.schoolName}</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-info" onClick={this.removeSchool.bind(this)}>O`chirish</button>
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.addModal} onClose={this.closeModal.bind(this)} center>
                    <div style={{margin: "30px 0 0 0 "}}>
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="schoolName">Maktab nomi</label>
                                <input id="schoolName" className="form-control" defaultValue={this.state.school.schoolName} onChange={this.setValue}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label htmlFor="userName">Login</label>
                                <input id="userName" className="form-control" defaultValue={this.state.school.userName} onChange={this.setValue}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label htmlFor="password">Parol</label>
                                <input id="password" className="form-control" defaultValue={this.state.school.password} onChange={this.setValue}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-info" onClick={this.addSchool.bind(this)}>Saqlash</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}