import React, {Component} from 'react';
import {apiURL, strings} from "./Utils";

class MediaView extends Component {

	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		document.getElementById('vidSrc').src = apiURL + '/video/' + urlParams.get('src') + '.mp4';
		let vid = document.getElementById('vid');
		vid.load();
		vid.play();
	}

	goBack() {
		this.props.history.goBack();
	}

	render() {
		return(
			<div>
				<section className="page-banner">
					<div className="image-layer" style={{backgroundImage: "url(images/bread-bg.jpg)"}}/>
					<div className="auto-container">
						<div className="breadcrumb-box"/>
						<h1>{strings.media}</h1>
					</div>
				</section>
				<section className="about-area inner-content-wrapper">
					<div className="container">
						<div className="col-lg-7 col-md-12">
							<div className="full-sec-content">
								<div className="sec-title style-two">
									<div className="ml-edu">
										<h4>{strings.effectiveMens}</h4>
									</div>
									<span className="decor"><span className="inner"/></span>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="media-show ml-box card p-3">
								<video width="100%" height="auto" controls id="vid">
									<source src="movie.mp4" type="video/mp4" id="vidSrc"/>
								</video>
							</div>
							<div className="mediaBot">
								<button className="btn pull-right">
									<i className="fa fa-download"/>
									{strings.download}
								</button>
								<button className="btn bg-info pull-right mr-2">
									<i className="fa fa-arrow-left"/>
									<span className="text-white" onClick={this.goBack.bind(this)}>{strings.back}</span>
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default MediaView;