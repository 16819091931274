import React, {Component} from "react";
import Header from './Header';
import HomePage from "./HomePage";
import Footer from "./Footer";
import {Route, Routes} from "react-router-dom";
import AboutProgram from "./AboutProgram";
import QuizView from "./QuizView";
import QuizStartView from "./QuizStartView";
import QuizSummaryView from "./QuizSummaryView";
import WorldOfProfessionsView from "./WorldOfProfessionsView";
import ProfessionView from "./ProfessionView";
import OccupationView from "./OccupationView";
import EffectiveView from "./EffectiveView";
import Effective2View from "./Effective2View";
import MediaView from "./MediaView";
import PsychoogicalAdviceView from "./PsychoogicalAdviceView";
import ContactView from "./ContactView";

import {strings} from "./Utils";
import OccupationMonthView from "./OccupationMonthView";
import OccupationMonthVideoView from "./OccupationMonthVideoView";
import LoginPage from "./LoginPage";
import SectionHomeView from "./SectionHomeView";
import PsychHelpListView from "./PsychHelpListView";
import AddPsychHelpView from "./AddPsychHelpView";
import OfficialDocumentsView from "./OfficialDocumentsView";
import AdminHomeView from "./AdminHomeView";
import SchoolHomePage from "./school/SchoolHomePage";
import DistrictHomeView from "./district/DistrictHomeView";
import RegionHomeView from "./region/RegionHomeView";
import MinistryHomeView from "./MinistryHomeView";
import EntrepreneurshipView from "./EntrepreneurshipView";
import IQTestView from "./IQTestView";
import AdminMenu from "./AdminMenu";
import InfographicListView from "./InfographicListView";
import EffectivePupilListView from "./EffectivePupilListView";
import EffectivePeopleListView from "./EffectivePeopleListView";
import SchoolListView from "./admin/SchoolListView";
import TestListView from "./admin/TestListView";
import AdminReportView from "./admin/AdminReportView";
import AdminPsychTestReportView from "./admin/AdminPsychTestReportView";
import Testing from "./kindergarten/Testing";
import PsychologistTest from "./kindergarten/PsychologistTest";

class App extends Component {

	constructor() {
		super();
		this.state = {
			language: 'uz'
		};
		strings.setLanguage(this.state.language);
        let href = window.location.href;
        if (!(href.endsWith('/school') || href.endsWith('/district') || href.endsWith('/region') || href.endsWith('/reports'))) {
            localStorage.setItem('sf', 1);
        } else {
            localStorage.setItem('sf', 0);
        }
	}

	changeLanguage(lang) {
		this.setState({
			language: lang
		});
		strings.setLanguage(lang);
	}

	render() {
	    let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
			if (user.role === 'ADMIN') {
				localStorage.setItem('sf', 0);
			}
		}
		let item = localStorage.getItem('sf');
		return (
			<div className="App">
				{(user !== null && user.role === 'ADMIN') ?
					<AdminMenu/> :
					<Header language={this.state.language} changeLanguage={this.changeLanguage.bind(this)}/>
				}
				<Routes>
					<Route path="/" exact element={<HomePage language={this.state.language}/>}/>
					<Route path="/about" element={<AboutProgram/>}/>
					<Route path="/quiz" element={<QuizView language={this.state.language}/>}/>
					<Route path="/quizStart" element={<QuizStartView language={this.state.language}/>}/>
					<Route path="/testing" element={<QuizSummaryView language={this.state.language}/>}/>
					<Route path="/professions" element={<WorldOfProfessionsView language={this.state.language}/>}/>
					<Route path="/professionView" element={<ProfessionView/>}/>
					<Route path="/occupation" element={<OccupationView language={this.state.language}/>}/>
					<Route path="/effective" element={<EffectiveView language={this.state.language}/>}/>
					<Route path="/effective2" element={<Effective2View/>}/>
					<Route path="/media" element={<MediaView/>}/>
					<Route path="/psychologicalAdvice" element={<PsychoogicalAdviceView language={this.state.language}/>}/>
					<Route path="/contact" element={<ContactView/>}/>
					<Route path="/oсcMonth" element={<OccupationMonthView language={this.state.language}/>}/>
					<Route path="/oсcMonthVideo" element={<OccupationMonthVideoView/>}/>
					<Route path="/login" element={<LoginPage/>} />
					<Route path="/section" element={<SectionHomeView/>}/>
					<Route path="/helpList" element={<PsychHelpListView/>}/>
					<Route path="/infoList" element={<InfographicListView/>}/>
					<Route path="/effPupils" element={<EffectivePupilListView/>}/>
					<Route path="/effPeoples" element={<EffectivePeopleListView/>}/>
					<Route path="/addHelp" element={<AddPsychHelpView/>}/>
					<Route path="/documentsView" element={<OfficialDocumentsView/>}/>
					<Route path="/ministry" element={<MinistryHomeView/>}/>
					<Route path="/reports" element={<AdminHomeView/>}/>
					<Route path="/region" element={<RegionHomeView/>}/>
					<Route path="/district" element={<DistrictHomeView/>}/>
					<Route path="/school" element={<SchoolHomePage/>}/>
					<Route path="/ent" element={<EntrepreneurshipView/>}/>
					<Route path="/iqTest" element={<IQTestView/>}/>
					<Route path="/schools" element={<SchoolListView/>}/>
					<Route path="/tests" element={<TestListView/>}/>
					<Route path="/adminReports" element={<AdminReportView/>}/>
					<Route path="/psychTestReports" element={<AdminPsychTestReportView/>}/>
					<Route path="/mtttest" element={<PsychologistTest/>}/>
				</Routes>
                {/*{Number(item) === 1 &&
                	<Footer changeLanguage={this.changeLanguage.bind(this)}/>
                }*/}
			</div>
		);
	}
}

export default App;
