import React, {Component} from 'react';
import axios from "axios";
import {apiURL, getKey, strings} from '../Utils';
import Swal from 'sweetalert2';
import Modal from "react-responsive-modal";
import AddPupilView from "./AddPupilView";


class SchoolPupilList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            districts: [],
            schools: [],
            pupil: null,
            pupils: [],
            addPupilModal: false,
            importPupilModal: false,
            editPupilModal: false,
            user: props.user
        }
    }

    componentDidMount() {
        this.getPupilList();
    }

    getPupilList() {
        let schoolId = this.state.user !== undefined && this.state.user !== null ? this.state.user.stateId : null;
        let klass = document.getElementById('klass').value;
        let klassLetter = document.getElementById('klassLetter').value;
        if (klass === undefined || klass === null) {
            klass = 11;
        }
        axios.get(apiURL + '/pupil/list2?schoolId=' + schoolId + '&klass=' + klass + '&klassLetter=' + klassLetter)
            .then(result => {
                if (result.data.statusCode === 200) {
                    let resultData = [];
                    if (result.data !== undefined && result.data !== null && result.data.data !== undefined && result.data.data !== null) {
                        resultData = result.data.data.map((row, index)=>{
                            return <tr key={getKey()}>
                                <td>{index + 1}</td>
                                <td>{row.fullKlass}</td>
                                <td>{row.lastName}</td>
                                <td>{row.firstName}</td>
                                <td>{row.middleName}</td>
                                <td>{row.birthDate}</td>
                                <td>
                                    <div className="d-flex">
                                        <button type="button" className="btn btn-default" aria-label="Left Align">
                                            <i className="fa fa-edit" style={{fontSize: "24px"}} aria-hidden="true"
                                               title={strings.edit} onClick={this.editPupil.bind(this, row)}/>
                                        </button>
                                        <button type="button" className="btn btn-default ml-2" aria-label="Left Align">
                                            <i className="fa fa-remove" aria-hidden="true" title={strings.delete}
                                               onClick={this.deletePupil.bind(this, row.id)}/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }

                    this.setState({
                        pupils: resultData
                    });
                }
            })
    }

    openAddPupilForm() {
        this.setState({
            addPupilModal: true
        })
    }

    closeAddPupilForm() {
        this.setState({
            addPupilModal: false
        })
    }

    openImportPupilForm() {
        this.setState({
            importPupilModal: true
        })
    }

    closeImportPupilForm() {
        this.setState({
            importPupilModal: false
        })
    }

    closeEditPupilForm() {
        this.setState({
            pupil: null,
            editPupilModal: false
        })
    }

    downloadPDF(pupil) {
        const link = document.createElement('a');
        link.href = apiURL + '/files/' + pupil.testResultFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    editPupil(pupil) {
        this.setState({
            pupil: pupil,
            editPupilModal: true
        })
    }

    deletePupil(pupilId) {
        Swal.fire({
            title: "O`quvchi ma`lumotini o`chirasizmi?",
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ha',
            cancelButtonText: 'Yo`q'
        }).then((result) => {
            if (result.value) {
                axios.post(apiURL + '/pupil/delete?pupilId=' + pupilId)
                    .then(data => {
                        if (data.data.statusCode === 200) {
                            this.getPupilList();
                            Swal.fire(
                                'O`chirildi!',
                                'O`quvchi ma`lumotlari o`chirildi',
                                'success'
                            )
                        } else {
                            Swal.fire(
                                'Xatolik',
                                'O`quvchi ma`lumotlarini o`chirishda xatolik',
                                'error'
                            )
                        }
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    'O`quvchi ma`lumotini o`chirish bekor qilindi',
                    'info'
                )
            }
        })
    }

    render() {
        return (
            <div>
                {this.state.user !== undefined && this.state.user !== null &&
                <div className="card card-body my-4 shadow-sm">
                    <div className="row">
                        <div className="col-md-2">
                            <select id="klass" className="form-control" onChange={this.getPupilList.bind(this)}>
                                <option value="6">6-sinf</option>
                                <option value="7">7-sinf</option>
                                <option value="8">8-sinf</option>
                                <option value="9">9-sinf</option>
                                <option value="10">10-sinf</option>
                                <option value="11">11-sinf</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select id="klassLetter" className="form-control" onChange={this.getPupilList.bind(this)}>
                                <option value=""> sinf</option>
                                <option value="A">A-sinf</option>
                                <option value="B">B-sinf</option>
                                <option value="V">V-sinf</option>
                                <option value="G">G-sinf</option>
                                <option value="D">D-sinf</option>
                                <option value="E">E-sinf</option>
                                <option value="J">J-sinf</option>
                                <option value="Z">Z-sinf</option>
                                <option value="I">I-sinf</option>
                                <option value="K">K-sinf</option>
                                <option value="L">L-sinf</option>
                                <option value="M">M-sinf</option>
                                <option value="N">N-sinf</option>
                                <option value="O">O-sinf</option>
                                <option value="P">P-sinf</option>
                                <option value="Q">Q-sinf</option>
                                <option value="R">R-sinf</option>
                                <option value="S">S-sinf</option>
                                <option value="T">T-sinf</option>
                                <option value="U">U-sinf</option>
                                <option value="F">F-sinf</option>
                                <option value="X">X-sinf</option>
                            </select>
                        </div>
                        <div className="col-md-8 text-right">
                            <button className="btn btn-success" onClick={this.openAddPupilForm.bind(this)}>O`quvchi
                                qo`shish
                            </button>
                        </div>
                    </div>

                    <Modal open={this.state.addPupilModal} center closeOnEsc={true}
                           onClose={this.closeAddPupilForm.bind(this)}>
                        <AddPupilView reloadList={this.getPupilList.bind(this)}/>
                    </Modal>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <table className="table table-bordered w-100">
                                <thead>
                                <tr>
                                    <th>№</th>
                                    <th>{strings.klass}</th>
                                    <th>{strings.lastName}</th>
                                    <th>{strings.firstName}</th>
                                    <th>{strings.middleName}</th>
                                    <th>{strings.birthDate}</th>
                                    <th>{strings.action}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.pupils}
                                </tbody>
                            </table>
                            <Modal open={this.state.editPupilModal} center closeOnEsc={true}
                                   onClose={this.closeEditPupilForm.bind(this)}>
                                <AddPupilView pupil={this.state.pupil} reloadList={this.getPupilList.bind(this)}/>
                            </Modal>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default SchoolPupilList;