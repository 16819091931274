import React, {Component} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {logout} from "./Utils";
import "react-datepicker/dist/react-datepicker.css";
import SurveyReportView from "./school/SurveyReportView";

class AdminHomeView extends Component {

	constructor() {
		super();
        let user = JSON.parse(localStorage.getItem('user'));
		this.state = {
            user: user,
			tabListKey: 'surveyReport',
			regionId: "",
			districtId: "",
			schoolId: "",
			klass: "",
			methodId: "",
			startDate: "",
			endDate: "",

			start: 0,
			offset: 100,
            perPage: 100,
            showLoading: false,
            pageCount: 1,
		};
		this.setValue = this.setValue.bind(this);
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			[name]: [value]
		})
	}

	render() {
		return (
			<div className="card card-body">
                {this.state.showLoading &&
				<div className="container">
					<div className="loader">
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--text"/>
					</div>
				</div>
                }
				<div className="text-right" style={{position: "absolute", right: "0"}}>
					<span className="p-3"><b>{this.state.user.lastName + ' ' + this.state.user.firstName}</b></span>
					<span className="cursor-pointer p-3 btn-info" onClick={logout}>
						<i className="fa fa-sign-out"/>&nbsp;Chiqish
					</span>
				</div>
				<Tabs id="controlled-tab-example" activeKey={this.state.tabListKey} onSelect={key => this.setState({tabListKey: key})}>
					<Tab eventKey="surveyReport" title="So`rovnoma hisoboti">
						<SurveyReportView user={this.state.user}/>
					</Tab>
				</Tabs>

			</div>
		)
	}
}

export default AdminHomeView;