import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import * as _ from './../Utils';
import {inputValueChanged, strings} from "../Utils";
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uz from 'date-fns/locale/uz';
registerLocale('uz', uz);
setDefaultLocale('uz');

export default class AddPupilView extends Component {

    constructor(props) {
        super(props);
        let pupil = props.pupil !== undefined && props.pupil !== null ? props.pupil : {
            id: null,
            lastName: '',
            firstName: '',
            middleName: '',
            klass: '',
            klassLetter: '',
            gender: {
                id: ''
            },
            birthDate2: new Date()
        };
        this.state = {
            pupil: pupil
        };
    }

    setBirthDate(date) {
        this.setState({
            pupil: {
                ...this.state.pupil,
                birthDate2: date.getTime()
            }
        })
    }

    savePupil() {
        let errors = 0;
        let lastName = document.getElementById('lastName').value;
        errors += _.validateField('lastName', lastName, errors);
        let firstName = document.getElementById('firstName').value;
        errors += _.validateField('firstName', firstName, errors);
        let middleName = document.getElementById('middleName').value;
        errors += _.validateField('middleName', middleName, errors);
        let sex = document.getElementById('gender').value;
        errors += _.validateSelect('gender', errors);
        let klass = document.getElementById('klass').value;
        errors += _.validateSelect('klass', errors);
        let klassLetter = document.getElementById('klassLetter').value;
        if (errors === 0) {
            let user = JSON.parse(localStorage.getItem('user'));
            let data = new FormData();
            if (this.state.pupil.id === undefined || this.state.pupil.id === null) {
                data.append('schoolId', Number(user.stateId));
            } else {
                data.append('id', Number(this.state.pupil.id));
            }
            data.append('lastName', lastName);
            data.append('firstName', firstName);
            data.append('middleName', middleName);
            data.append('klass',  Number(klass));
            data.append('klassLetter', klassLetter);
            data.append('birthDate', Number(this.state.pupil.birthDate2));
            data.append('sex', Number(sex));
            axios.post(_.apiURL + ((this.state.pupil.id === undefined || this.state.pupil.id === null) ? '/pupil/add' : '/pupil/edit'), data)
                .then(result=>{
                    if (result.data.statusCode === 200) {
                        swal(result.data.message, '', 'success');
                        this.props.reloadList();
                    } else {
                        swal(result.data.message, '', 'error');
                    }
                })
        } else {
            swal("Hamma ma'lumotlarni kiriting", '', 'error');
        }
    }

    render() {
        return(
            <div>
                <div className="form-row p-5">
                    <div className="col-md-12 text-center">
                        <h4>Oʻquvchi ma'lumotini kiritish</h4>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="lastName">Familiyasi</label>
                        <input className="form-control" id="lastName" defaultValue={this.state.pupil.lastName} onChange={inputValueChanged}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="firstName">Ismi</label>
                        <input className="form-control" id="firstName" defaultValue={this.state.pupil.firstName} onChange={inputValueChanged}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="middleName">Sharifi</label>
                        <input className="form-control" id="middleName" defaultValue={this.state.pupil.middleName} onChange={inputValueChanged}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-4">
                        <label htmlFor="klass">Sinfi</label>
                        <select className="form-control" id="klass" defaultValue={this.state.pupil.klass} onChange={inputValueChanged}>
                            <option value="">{strings.pleaseSelect}</option>
                            <option value="6">6-sinf</option>
                            <option value="7">7-sinf</option>
                            <option value="8">8-sinf</option>
                            <option value="9">9-sinf</option>
                            <option value="10">10-sinf</option>
                            <option value="11">11-sinf</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="klassLetter">&nbsp;</label>
                        <select className="form-control" id="klassLetter" defaultValue={this.state.pupil.klassLetter} onChange={inputValueChanged}>
                            <option value=""> sinf</option>
                            <option value="A">A-sinf</option>
                            <option value="B">B-sinf</option>
                            <option value="V">V-sinf</option>
                            <option value="G">G-sinf</option>
                            <option value="D">D-sinf</option>
                            <option value="E">E-sinf</option>
                            <option value="Yo">Yo-sinf</option>
                            <option value="J">J-sinf</option>
                            <option value="Z">Z-sinf</option>
                            <option value="I">I-sinf</option>
                            <option value="Y">Y-sinf</option>
                            <option value="K">K-sinf</option>
                            <option value="L">L-sinf</option>
                            <option value="M">M-sinf</option>
                            <option value="N">N-sinf</option>
                            <option value="O">O-sinf</option>
                            <option value="P">P-sinf</option>
                            <option value="Q">Q-sinf</option>
                            <option value="R">R-sinf</option>
                            <option value="S">S-sinf</option>
                            <option value="T">T-sinf</option>
                            <option value="U">U-sinf</option>
                            <option value="F">F-sinf</option>
                            <option value="X">X-sinf</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="sex">Jinsi</label>
                        <select className="form-control" id="gender" defaultValue={this.state.pupil.gender.id} onChange={inputValueChanged}>
                            <option value="">Tanlang</option>
                            <option value="1">O`g`il</option>
                            <option value="2">Qiz</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="sex">Tug`ilgan vaqti</label>
                        <DatePicker id="birthDate" className="form-control" locale="uz" dateFormat="dd.MM.yyyy"
                                    placeholderText={strings.selectDate} selected={this.state.pupil.birthDate2 !== null ? this.state.pupil.birthDate2 : new Date()}
                                    onChange={date => this.setBirthDate(date)}/>
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="col-md-12 text-center">
                        <button className="btn-success" onClick={this.savePupil.bind(this)}>Saqlash</button>
                    </div>
                </div>
            </div>
        )
    }
}