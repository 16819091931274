import React, {Component} from 'react';
import swal from 'sweetalert';
import axios from "axios";
import * as _ from './../Utils';
import {getRegions} from "../Utils";
import Testing from "./Testing";
import authHeader from "../auth-header";

export default class PsychologistTest extends Component {

    constructor(props) {
        super(props);
        let userData = JSON.parse(localStorage.getItem('userData'));
        let hash = localStorage.getItem("checkHash");
        this.state = {
            districts: [],
            mttList: [],
            pupils: [],
            methods: [],
            sciences: [],
            userData: userData,
            toTesting: false,
            page: (hash !== undefined && hash !== null && hash !== '' && hash.length > 40) ? "start" : "authentication"
        }
    }

    startTest() {
        let errors = 0;
        let districtId = document.getElementById('district').value;
        if (districtId === '' || districtId === '0') {
            errors = _.validateSelect('district', errors);
        }
        let mttId = document.getElementById('mttName').value;
        if (mttId === '' || mttId === '0') {
            errors = _.validateSelect('mttName', errors);
        }
        let lastName = document.getElementById('lastName').value;
        errors = _.validateField('lastName', lastName, errors);
        let firstName = document.getElementById('firstName').value;
        errors = _.validateField('firstName', firstName, errors);
        let middleName = document.getElementById('middleName').value;
        errors = _.validateField('middleName', middleName, errors);
        let gender = document.getElementById('gender').value;
        if (gender === '' || gender === '0') {
            errors = _.validateSelect('gender', errors);
        }
        let education = document.getElementById('education').value;
        if (education === '' || education === '0') {
            errors = _.validateSelect('education', errors);
        }
        let position = document.getElementById('position').value;
        if (position === '' || position === '0') {
            errors = _.validateSelect('position', errors);
        }
        let age = document.getElementById('age').value;
        if (age === '' || Number(age) === 0) {
            errors = _.validateField('age', errors);
        }
        let language = Number(document.getElementById('language').value);
        if (errors > 0) {
            swal("Barcha ma'lumotlarni kiriting", '', 'error');
            return;
        }
        let transferData = new FormData();
        transferData.append("mttId", Number(mttId));
        transferData.append("lastName", encodeURIComponent(lastName.trim()));
        transferData.append("firstName", encodeURIComponent(firstName.trim()));
        transferData.append("middleName", encodeURIComponent(middleName.trim()));
        transferData.append("gender", gender);
        transferData.append("education", education);
        transferData.append("position", position);
        transferData.append("age", Number(age));

        axios.post(_.apiURL + '/user/kg/psychologist/add', transferData)
            .then(result => {
                if (result.status === 200) {
                    localStorage.setItem('language', language);
                    localStorage.setItem('psychologist', JSON.stringify({
                        "id": result.data.content.id,
                        "name": result.data.content.lastName + ' ' + result.data.content.firstName
                    }));
                    axios.get(_.apiURL + '/test/psychTestComplex?language=' + language)
                        .then(result => {
                            if (result.status === 200) {
                                localStorage.setItem('complexTest', JSON.stringify(result.data.data));
                                this.setState({
                                    page: "testingProcess"
                                });
                            }
                        });

                }
            });
    }

    onChangeStates() {
        let stateId = document.getElementById('region').value;
        axios.get(_.apiURL + '/pupil/district/list?stateId=' + stateId)
            .then(data => {
                let districts = [];
                if (data.data.statusCode === 200) {
                    districts.push(data.data.data.map(user => {
                        return <option key={user.id} value={user.id}>{user.name}</option>
                    }));
                }
                this.setState({
                    districts: districts
                })
            })
    }

    onChangeDistrict() {
        let stateId = document.getElementById('district').value;
        axios.get(_.apiURL + '/pupil/kindergarten/list?districtId=' + stateId)
            .then(data => {
                let districts = [];
                if (data.data.statusCode === 200) {
                    districts.push(data.data.data.map(user => {
                        return <option key={user.id} value={user.id}>{user.name}</option>
                    }));
                }
                this.setState({
                    mttList: districts
                })
            })
    }

    signIn() {
        let userName = document.getElementById('userName').value;
        let password = document.getElementById('password').value;
        if (password !== null && password !== '') {
            var data = new FormData();
            data.append("userName", userName);
            data.append("password", password);
            axios.post(_.apiURL + '/user/login?userName=' + userName + '&password=' + password, authHeader()).then(result=>{
                if (result.data.statusCode === 200) {
                    this.setState({
                        page: "start"
                    });
                    localStorage.setItem('checkHash', result.data.data.status6);
                }
            });
        }
    }

    render() {
        let page = this.state.page;
        let regions = getRegions('uz');
        let hash = localStorage.getItem("checkHash");
        if (hash === undefined || hash === null || hash === '') {
            page = "authentication";
        } else if (hash !== undefined && hash !== null && hash !== '' && hash.length > 40) {
            if (hash.includes("_")) {
                let strings = hash.split("_");
                let date = new Date(Number(strings[0]));
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);

                let date1 = new Date();
                date1.setHours(0);
                date1.setMinutes(0);
                date1.setSeconds(0);
                date1.setMilliseconds(0);
                if (date < date1) {
                    page = "authentication";
                }
            } else {
                page = "start";
            }
        } else {
            page = "testingProcess";
        }
        console.log(page);
        return (
            <div className="content">
                {/*<div style={{height:'100vh', margin: "auto", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <h3 style={{border: "7px solid rgb(255, 0, 0)", padding: "20px", borderRadius: "20px", textShadow: "red 3px 3px 10px", boxShadow: "0px 5px 12px 0 rgba(42, 14, 21, 0.5), 8px 10px 20px 0px rgba(50, 40, 40, 0.6)"}}>Ushbu test vaqtincha to`xtatilgan.</h3>
                </div>*/}
                {page === "authentication" &&
                    <div className="w-100">
                        <div className="card card-body" style={{width: "25%", margin: "3rem auto"}}>
                            <div className="form-row mb-2">
                                <div className="col-md-3">
                                    <span>Login: </span>
                                </div>
                                <div className="col-md-9">
                                    <input className="form-control" id="userName" type="text" placeholder="Login"
                                           value="MTTXodimi"/>
                                </div>
                            </div>
                            <div className="form-row mb-2">
                                <div className="col-md-3">
                                    <span>Parol: </span>
                                </div>
                                <div className="col-md-9">
                                    <input className="form-control" id="password" type="password" placeholder="Parol"/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-9">
                                    <button className="btn btn-primary btn-lg btn-block" type="button" onClick={this.signIn.bind(this)}>
                                        Kirish
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {page === "start" &&
                <div className="kg-container container card card-body my-4 shadow-sm">
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Hudud:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='region' className="form-control" onChange={this.onChangeStates.bind(this)}>
                                {regions}
                            </select>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Tuman/Shahar:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='district' className="form-control" onChange={this.onChangeDistrict.bind(this)}>
                                {this.state.districts}
                            </select>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>MTT nomi:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='mttName' className="form-control">
                                {this.state.mttList}
                            </select>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Familiyasi:</span>
                        </div>
                        <div className="col-md-9">
                            <input id='lastName' className="form-control" type="text"/>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Ismi:</span>
                        </div>
                        <div className="col-md-9">
                            <input id='firstName' className="form-control" type="text"/>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Otasining ismi:</span>
                        </div>
                        <div className="col-md-9">
                            <input id='middleName' className="form-control" type="text"/>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Jinsi:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='gender' className="form-control">
                                <option value={""}>Tanlang</option>
                                <option value="MAN">Erkak</option>
                                <option value="WOMAN">Ayol</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Yoshi:</span>
                        </div>
                        <div className="col-md-9">
                            <input id='age' className="form-control" type="number"/>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Ma'lumoti:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='education' className="form-control">
                                <option value="OLIY">Oliy</option>
                                <option value="URTA_MAXSUS">O`rta maxsus</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Lavozimi:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='position' className="form-control">
                                <option value="DIREKTOR">Direktor</option>
                                <option value="DIR_URIN">Direktor o`rinbosari/Metodist</option>
                                <option value="TARBIYACHI">Tarbiyachi</option>
                                <option value="TARBIYACHI_YORDAM">Tarbiyachi yordamchisi</option>
                                <option value="PSIXOLOG">Psixolog</option>
                                <option value="MUSIC_PR">Musiqa rahbari</option>
                                <option value="JT_PR">Jismoniy tarbiya rahbari</option>
                                <option value="CHT_PR">Chet tili o`qituvchisi</option>
                                <option value="LOGOPED">Logoped/Defektolog</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row form-group">
                        <div className="col-md-3">
                            <span>Test tili:</span>
                        </div>
                        <div className="col-md-9">
                            <select id='language' className="form-control">
                                <option value={1}>O`zbek</option>
                                <option value={2}>Русский</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row text-center">
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-9">
                            <button className="btn btn-success" onClick={this.startTest.bind(this)}>Testni
                                boshlang
                            </button>
                        </div>
                    </div>
                </div>
                }
                {page === "testingProcess" &&
                    <Testing/>
                }
            </div>
        )
    }
}