import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiURL, strings} from "../Utils";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default class ReportContent extends Component {

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            tabListKey: 'klass7',
            showLoading: false,
            user: user,
            methodId: 0,
            startDate: new Date(),
            endDate: new Date(),
            table1Rows: [],
            table2Rows: [],
            table3Rows: [],
            methods: [],

            header1: 'O‘z kuchlariga ishonuvchan, to‘siqlarni yengishda tirishqoq, yuqori darajadagi maqsadga ega',
            header2: 'Maqsadlarning o‘rtacha darajasi, maqsadlarni o‘rtacha tavakkalchilik bilan qo‘yadi',
            header3: 'Biror narsaga erishish uchun maqsadlar darajasi past, o‘ziga ishonchsizlik',
            header4: '',
            header5: '',
            header6: '',
            header7: '',
            header8: '',
            header9: '',
            header10: '',
            header11: '',
            header12: '',
            header13: '',
            header14: '',
            header15: '',

            reportTitle: '',
        }
    }

    componentDidMount() {
        let methods = [];
        methods.push(<option key="opt0" value="0">Tanlang</option>);
        if (+this.props.klass === 5) {
            methods.push(<option key="opt45" value="45">IQ test</option>);
            methods.push(<option key="opt26" value="26">Maqsadlarga intilish testi</option>);
        } else if (+this.props.klass === 6) {
            methods.push(<option key="opt44" value="44">IQ test</option>);
            methods.push(<option key="opt31" value="31">O`quvchilarning qiziqishlari, qobiliyatlari va intilishlarini aniqlash metodikasi</option>);
        } else if (+this.props.klass === 7) {
            methods.push(<option key="opt44" value="46">IQ test</option>);
            methods.push(<option key="opt26" value="26">Maqsadlarga intilish testi</option>);
            methods.push(<option key="opt27" value="27">Tashkilotchilik qobiliyatini aniqlash testi</option>);
            methods.push(<option key="opt28" value="28">Kasb tiplarini aniqlash metodikasi</option>);
        } else if (+this.props.klass === 8) {
            methods.push(<option key="opt29" value="29">Muloqоtchanlikning darajasini aniqlash testi</option>);
            methods.push(<option key="opt30" value="30">O`quvchilarning qiziqishlarini aniqlash so`rovnomasi</option>);
            methods.push(<option key="opt31" value="31">O`quvchilarning qiziqishlari, qobiliyatlari va intilishlarini aniqlash metodikasi</option>);
        } else if (+this.props.klass === 9) {
            methods.push(<option key="opt32" value="32">Shaxs yo`nalganligini aniqlash metodikasi</option>);
            methods.push(<option key="opt33" value="33">O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash bo`yicha test</option>);
            methods.push(<option key="opt34" value="34">Kasb tanlash motivini aniqlash metodikasi</option>);
            methods.push(<option key="opt35" value="35">Qiziqishlar xaritasi metodikasi</option>);
        } else if (+this.props.klass === 10) {
            methods.push(<option key="opt36" value="36">O`quv motivatsiyasining yo`nalganligini diagnostika qilish metodikasi</option>);
            methods.push(<option key="opt33" value="33">O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash bo`yicha test</option>);
            methods.push(<option key="opt38" value="38">Kasbiy moyillik so`rovnomasi</option>);
            methods.push(<option key="opt35" value="35">Qiziqishlar xaritasi metodikasi</option>);
        } else if (+this.props.klass === 11) {
            methods.push(<option key="opt33" value="33">O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash bo`yicha test</option>);
            methods.push(<option key="opt28" value="28">Kasb tiplarini aniqlash metodikasi</option>);
            methods.push(<option key="opt35" value="35">Qiziqishlar xaritasi metodikasi</option>);
        }
        let start = new Date(this.state.startDate.setMonth(this.state.startDate.getMonth() - 6));
        this.setState({
            methods: methods,
            startDate: start
        });
        if (this.props.klass !== '' && Number(this.state.methodId) > 0) {
            this.getData(this.state.methodId);
        }
    }

    getData(methodId) {
        axios.get(apiURL + '/report/districtTestResultReport?districtId=&klass=' + this.props.klass +
            '&methodId=' + methodId + '&startDate=' + this.state.startDate.getTime() + '&endDate=' + this.state.endDate.getTime() + '&userType=' + this.props.userType)
            .then(result => {
                if (result.data.statusCode === 200) {
                    let guys = 0, girls = 0, result1 = 0, result2 = 0, result3 = 0, result4 = 0, result5 = 0, result6 = 0, result7 = 0, result8 = 0, result9 = 0,
                        result10 = 0, result11 = 0, result12 = 0, result13 = 0, result14 = 0, result15 = 0;
                    let rows = result.data.data.map((row, index) => {
                        guys += row.guys;
                        girls += row.girls;
                        result1 += row.testResult1;
                        result2 += row.testResult2;
                        result3 += row.testResult3;
                        if (+this.state.methodId === 28 || +this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 35 || +this.state.methodId === 38) {
                            result4 += row.testResult4;
                            result5 += row.testResult5;
                        }
                        if (+this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 35 || +this.state.methodId === 38) {
                            result6 += row.testResult6;
                            result7 += row.testResult7;
                        }
                        if (+this.state.methodId === 31 || +this.state.methodId === 35) {
                            result8 += row.testResult8;
                            result9 += row.testResult9;
                        }
                        if (+this.state.methodId === 33 || +this.state.methodId === 35) {
                            result10 += row.testResult10;
                            result11 += row.testResult11;
                            result12 += row.testResult12;
                            result13 += row.testResult13;
                            result14 += row.testResult14;
                            result15 += row.testResult15;
                        }
                        return <tr key={'trr_' + index}>
                            <td>{index + 1}</td>
                            <td>{row.schoolName}</td>
                            <td className="text-center">{(row.guys + row.girls)}</td>
                            <td className="text-center">{row.guys}</td>
                            <td className="text-center">{row.girls}</td>
                            <td className="text-center">{row.testResult1}</td>
                            <td className="text-center">{row.testResult2}</td>
                            <td className="text-center">{row.testResult3}</td>
                            {(+this.state.methodId === 28 || +this.state.methodId === 29 || +this.state.methodId === 30 ||
                                +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 36 || +this.state.methodId === 38) &&
                            <td className="text-center">{row.testResult4}</td>
                            }
                            {(+this.state.methodId === 28 || +this.state.methodId === 29 || +this.state.methodId === 30 ||
                                +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 36 || +this.state.methodId === 38) &&
                            <td className="text-center">{row.testResult5}</td>
                            }
                            {(+this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 38) &&
                            <td className="text-center">{row.testResult6}</td>
                            }
                            {(+this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 31 || +this.state.methodId === 35) &&
                            <td className="text-center">{row.testResult7}</td>
                            }
                            {(+this.state.methodId === 31 || +this.state.methodId === 35) &&
                            <td className="text-center">{row.testResult8}</td>
                            }
                            {(+this.state.methodId === 31 || +this.state.methodId === 35) &&
                            <td className="text-center">{row.testResult9}</td>
                            }
                            {(+this.state.methodId === 33 || +this.state.methodId === 35) &&
                            <td className="text-center">{row.testResult10}</td>
                            }
                            {(+this.state.methodId === 33) &&
                            <td className="text-center">{row.testResult11}</td>
                            }
                            {(+this.state.methodId === 33) &&
                            <td className="text-center">{row.testResult12}</td>
                            }
                            {(+this.state.methodId === 33) &&
                            <td className="text-center">{row.testResult13}</td>
                            }
                            {(+this.state.methodId === 33) &&
                            <td className="text-center">{row.testResult14}</td>
                            }
                            {(+this.state.methodId === 33) &&
                            <td className="text-center">{row.testResult15}</td>
                            }
                        </tr>
                    });
                    let lastRow = <tr key={'trr_10000'}>
                        <td colSpan={2} className="text-center"><b>JAMI:</b></td>
                        <td className="text-center"><b>{(guys + girls)}</b></td>
                        <td className="text-center"><b>{guys}</b></td>
                        <td className="text-center"><b>{girls}</b></td>
                        <td className="text-center"><b>{result1}</b></td>
                        <td className="text-center"><b>{result2}</b></td>
                        <td className="text-center"><b>{result3}</b></td>
                        {(+this.state.methodId === 28 || +this.state.methodId === 29 || +this.state.methodId === 30 ||
                            +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 36 || +this.state.methodId === 38) &&
                        <td className="text-center"><b>{result4}</b></td>
                        }
                        {(+this.state.methodId === 28 || +this.state.methodId === 29 || +this.state.methodId === 30 ||
                            +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 36 || +this.state.methodId === 38) &&
                        <td className="text-center"><b>{result5}</b></td>
                        }
                        {(+this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 38) &&
                        <td className="text-center"><b>{result6}</b></td>
                        }
                        {(+this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 31 || +this.state.methodId === 35) &&
                        <td className="text-center"><b>{result7}</b></td>
                        }
                        {(+this.state.methodId === 31 || +this.state.methodId === 35) &&
                        <td className="text-center"><b>{result8}</b></td>
                        }
                        {(+this.state.methodId === 31 || +this.state.methodId === 35) &&
                        <td className="text-center"><b>{result9}</b></td>
                        }
                        {(+this.state.methodId === 33 || +this.state.methodId === 35) &&
                        <td className="text-center"><b>{result10}</b></td>
                        }
                        {(+this.state.methodId === 33) &&
                        <td className="text-center"><b>{result11}</b></td>
                        }
                        {(+this.state.methodId === 33) &&
                        <td className="text-center"><b>{result12}</b></td>
                        }
                        {(+this.state.methodId === 33) &&
                        <td className="text-center"><b>{result13}</b></td>
                        }
                        {(+this.state.methodId === 33) &&
                        <td className="text-center"><b>{result14}</b></td>
                        }
                        {(+this.state.methodId === 33) &&
                        <td className="text-center"><b>{result15}</b></td>
                        }
                    </tr>;
                    rows.push(lastRow);
                    this.setState({
                        table1Rows: rows,
                        showLoading: false
                    })
                } else {
                    this.setState({
                        table1Rows: [],
                        showLoading: false
                    })
                }
            })
    }

    onChangeMethod(e) {
        let header1 = '', header2 = '', header3 = '', header4 = '', header5 = '', header6 = '', header7 = '', header8 = '', header9 = '',
            header10 = '', header11 = '', header12 = '', header13 = '', header14 = '', header15 = '';
        if (Number(e.target.value) === 26) {
            header1 = 'O‘z kuchlariga ishonuvchan, to‘siqlarni yengishda tirishqoq, yuqori darajadagi maqsadga ega';
            header2 = 'Maqsadlarning o‘rtacha darajasi, maqsadlarni o‘rtacha tavakkalchilik bilan qo‘yadi';
            header3 = 'Biror narsaga erishish uchun maqsadlar darajasi past, o‘ziga ishonchsizlik';
        } else if (Number(e.target.value) === 27) {
            header1 = 'Yuqori darajadagi tashkilotchilik qobiliyatidan darak beradi';
            header2 = 'Tashkilochilik qobiliyatining o‘rtacha darajasi';
            header3 = 'Tashkilotchilik qobiliyatining juda pastligi';
        } else if (Number(e.target.value) === 28) {
            header1 = 'Tabiat';
            header2 = 'Texnika';
            header3 = 'Belgi';
            header4 = 'San’at';
            header5 = 'Inson';
        } else if (Number(e.target.value) === 29) {
            header1 = 'Muloqotchanlik darajasi ancha past';
            header2 = 'Yolg‘izlanishni yaxshi ko‘radi';
            header3 = 'Ma’lum darajada muloqotchan';
            header4 = 'Yetarlicha muloqotchan';
            header5 = 'Juda muloqotchan, ba’zida me’yoridan ortiq';
            header6 = 'Juda muloqotchan, gapi ichiga sig‘maydi';
            header7 = 'Muloqotchanlik darajasi me’yordan yuqori darajada';
        } else if (Number(e.target.value) === 30) {
            header1 = 'Matematika va texnika';
            header2 = 'Gumanitar soha';
            header3 = 'Badiiy faoliyat';
            header4 = 'Jismoniy tarbiya va sport';
            header5 = 'Kommunikativ soha';
            header6 = 'Tabiat va tabiatshunoslik';
            header7 = 'Uy yumushlari, o‘z-o‘ziga xizmat ko‘rsatish';
        } else if (Number(e.target.value) === 31) {
            header1 = 'Jismoniy (sport)';
            header2 = 'Tashkilotchilik';
            header3 = 'Matematik';
            header4 = 'Konstruktorlik-texnika';
            header5 = 'Tasviriy (artistik)';
            header6 = 'Kommunikativ';
            header7 = 'Musiqiy';
            header8 = 'Badiiy-tasviriy';
            header9 = 'Filologik (til-adabiyot, jurnalistika)';
        } else if (Number(e.target.value) === 32) {
            header1 = 'O‘ziga yo‘nalganlik (Men)';
            header2 = 'Muloqotga yo‘nalganlik (Muloqot)';
            header3 = 'Faoliyatga yo‘nalganlik (Faoliyat)';
        } else if (Number(e.target.value) === 33) {
            header1 = 'Fizika';
            header2 = 'Matematika';
            header3 = 'Iqtisod va biznes';
            header4 = 'Texnika va elektrotexnika';
            header5 = 'Kimyo';
            header6 = 'Biologiya va qishloq xo‘jaligi';
            header7 = 'Tibbiyot';
            header8 = 'Geografiya va geologiya';
            header9 = 'Tarix';
            header10 = 'Filologiya, jurnalistika';
            header11 = 'San\'at';
            header12 = 'Pedagogika';
            header13 = 'Xizmat ko‘rsatish sohasidagi mehnat faoliyati';
            header14 = 'Harbiy ish';
            header15 = 'Sport';
        } else if (Number(e.target.value) === 34) {
            header1 = 'Nufuzli kasbni egallash, jamiyatda mavqega ega bo‘lishga intilish motivining ustunligi';
            header2 = 'Tanlangan kasbdan moddiy manfaatdorlikka ega bo‘lish motivining ustunligi';
            header3 = 'Tanlangan kasbning ijod bilan bog‘liqligi, yangi texnologiyalarni yaratish, zarur malaka va ko‘nikmalarni egallashga intilish';
        } else if (Number(e.target.value) === 35) {
            header1 = 'Fizika va matematika';
            header2 = 'Kimyo va biologiya';
            header3 = 'Radiotexnika va elektronika';
            header4 = 'Mexanika va konstruktorlik';
            header5 = 'Geografiya va geologiya';
            header6 = 'Adabiyot va san’at';
            header7 = 'Tarix va siyosat';
            header8 = 'Pedagogika (psixologiya) va tibbiyot';
            header9 = 'Tadbirkorlik va uy ro‘zg‘or ishlari';
            header10 = 'Sport va harbiy soha';
        } else if (Number(e.target.value) === 36) {
            header1 = 'Tashqi motivatsiya ustun';
            header2 = 'Ichki motivatsiya ustun';
            header3 = 'Ichki motivatsiya past darajada';
            header4 = 'Ichki motivatsiya o`rta darajada';
            header5 = 'Ichki motivatsiya yuqori darajada';
        } else if (Number(e.target.value) === 38) {
            header1 = 'Odamlar bilan ishlashga moyillik';
            header2 = 'Kashfiyotchilik (zakovatli) kasblarga moyillik';
            header3 = 'Amaliyot ishlarga moyillik';
            header4 = 'Estetik turdagi kasblarga moyillik';
            header5 = 'Ekstremal turdagi faoliyatga moyillik';
            header6 = 'Iqtisodiy reja faoliyat turiga moyillik';
        } else if (Number(e.target.value) === 44 || Number(e.target.value) === 45 || Number(e.target.value) === 46) {
            header1 = 'Intellekt darajasi yuqori';
            header2 = 'Intellekt darajasi o‘rta';
            header3 = 'Intellekt darajasi o‘rtadan past';
        }
        let selectedMethod = e.target;
        let methodName = selectedMethod.options[selectedMethod.selectedIndex].text;
        let reportTitle = (methodName !== '' && methodName !== 'Tanlang') ? 'Umumiy o‘rta ta’lim maktablarining ' + this.props.klass + '-sinf o‘quvchilarida o‘tkazilgan “' + methodName + '” testi natijalari to‘g‘risida ma’lumot' : '';
        this.setState({
            header1: header1,
            header2: header2,
            header3: header3,
            header4: header4,
            header5: header5,
            header6: header6,
            header7: header7,
            header8: header8,
            header9: header9,
            header10: header10,
            header11: header11,
            header12: header12,
            header13: header13,
            header14: header14,
            header15: header15,
            methodId: e.target.value,
            reportTitle: reportTitle
        });
        if (this.props.klass !== '') {
            this.getData(e.target.value);
        }
    }

    exportToExcel(formId) {
        let schoolId = this.state.user.stateId;
        let klass = this.props.klass;
        let methodId = this.state.methodId;
        if (methodId === null || methodId === '') {
            swal('Metodikani tanlang', '', 'error');
            return;
        }
        cookies.set('userType', this.props.userType, {path: '/'});
        cookies.set('relatedId', schoolId, {path: '/'});
        cookies.set('klass', klass, {path: '/'});
        cookies.set('methodId', methodId, {path: '/'});
        let form = document.getElementById(formId);
        form.submit();
    }

    render() {
        return (
            <div>
                <div className="form-row mb-3 mt-3">
                    <div className="col-md-2">
                        <select id="methods" className="form-control" onChange={this.onChangeMethod.bind(this)}>
                            {this.state.methods}
                        </select>
                    </div>
                    <div className="col-md-10 text-right">
                        {this.state.user.role === 'ADMIN' &&
                        <form method="post" action={apiURL + "/common/psychTestResultExcel"}>
                            <button className="btn-success" onClick={this.exportToExcel.bind(this, 'excelForm')}><i
                                className="fa fa-file-excel-o" title={strings.exportToExcel}/></button>
                        </form>
                        }
                    </div>
                </div>

                <div className="form-row mb-3">
                    <div className="col-md-12 text-center">
                        <span className="table-title">{this.state.reportTitle}</span>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead className="font-times">
                            <tr>
                                <th rowSpan={2}>№</th>
                                <th rowSpan={2}>Maktab nomi</th>
                                <th rowSpan={2}>Jami o‘quvchilar soni</th>
                                <th colSpan={2}>Shundan</th>
                                <th colSpan={(+this.state.methodId === 28 || +this.state.methodId === 36) ? 5 : (+this.state.methodId === 38) ? 6 : (+this.state.methodId === 29 || +this.state.methodId === 30) ? 7 :
                                    (+this.state.methodId === 31) ? 9 : (+this.state.methodId === 33) ? 15 : (+this.state.methodId === 35) ? 10 : 3}>Natija</th>
                            </tr>
                            <tr>
                                <th>O‘g‘il</th>
                                <th>Qiz</th>
                                <th>{this.state.header1}</th>
                                <th>{this.state.header2}</th>
                                <th>{this.state.header3}</th>
                                {(+this.state.methodId === 28 || +this.state.methodId === 29 || +this.state.methodId === 30 ||
                                    +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 36 || +this.state.methodId === 38) &&
                                <th>{this.state.header4}</th>
                                }
                                {(+this.state.methodId === 28 || +this.state.methodId === 29 || +this.state.methodId === 30 ||
                                    +this.state.methodId === 31 || +this.state.methodId === 35 || +this.state.methodId === 36 || +this.state.methodId === 38) &&
                                <th>{this.state.header5}</th>
                                }
                                {(+this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 31 ||
                                    +this.state.methodId === 35 || +this.state.methodId === 38) &&
                                <th>{this.state.header6}</th>
                                }
                                {(+this.state.methodId === 29 || +this.state.methodId === 30 || +this.state.methodId === 31 || +this.state.methodId === 35) &&
                                <th>{this.state.header7}</th>
                                }
                                {(+this.state.methodId === 31 || +this.state.methodId === 35) &&
                                <th>{this.state.header8}</th>
                                }
                                {(+this.state.methodId === 31 || +this.state.methodId === 35) &&
                                <th>{this.state.header9}</th>
                                }
                                {(+this.state.methodId === 33 || +this.state.methodId === 35) &&
                                <th>{this.state.header10}</th>
                                }
                                {(+this.state.methodId === 33) &&
                                <th>{this.state.header11}</th>
                                }
                                {(+this.state.methodId === 33) &&
                                <th>{this.state.header12}</th>
                                }
                                {(+this.state.methodId === 33) &&
                                <th>{this.state.header13}</th>
                                }
                                {(+this.state.methodId === 33) &&
                                <th>{this.state.header14}</th>
                                }
                                {(+this.state.methodId === 33) &&
                                <th>{this.state.header15}</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.table1Rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}