import React, {Component} from 'react';

class OccupationMonthView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			regionId: null,
			openTestPage:false,
			regions: this.props.language === 'uz' ? ['Qoraqalpog`iston Respublikasi','Andijon viloyati','Buxoro viloyati','Qashqadaryo viloyati','Jizzax viloyati',
				'Navoiy viloyati','Namangan viloyati','Samarqand viloyati','Sirdaryo viloyati','Surxondaryo viloyati','Toshkent viloyati','Farg`ona viloyati','Xorazm viloyati','Toshkent shahri'] :
			['Республика Каракалпакстан','Андижанская область', 'Бухарская область', 'Кашкадарьинская область', 'Джизакская область', 'Навоийская область', 'Наманганская область', 'Самаркандская область',
				'Сырдарьинская область', 'Сурхандарьинская область', 'Ташкентская область', 'Ферганская область', 'Хорезмская область', 'город Ташкент']
		}
	}

	openOccupationMonthVideoPage(regionId) {
		window.localStorage.setItem('regionId', regionId);
		this.setState({
			regionId: regionId,
			openTestPage: true
		});
	}

	renderRedirect = () => {
		if (this.state.openTestPage) {
			window.open('/oсcMonthVideo', '_self');
		}
	};

	render() {
		return (
			<div>
				{this.renderRedirect()}

				<div className="container mt-3">
					<div className="row mb-3">
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 1)} style={{backgroundImage: "url('images/regions/qqr.jpg')"}}>
								<span>{this.state.regions[0]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 2)} style={{backgroundImage: "url('images/regions/andijon.jpg')"}}>
								<span>{this.state.regions[1]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 3)} style={{backgroundImage: "url('images/regions/buxoro.jpg')"}}>
								<span>{this.state.regions[2]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 4)}
							     style={{backgroundImage: "url('images/regions/qashqadaryo.jpg')"}}>
								<span>{this.state.regions[3]}</span>
							</div>
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 5)} style={{backgroundImage: "url('images/regions/jizzax.jpg')"}}>
								<span>{this.state.regions[4]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 6)} style={{backgroundImage: "url('images/regions/navoiy.jpg')"}}>
								<span>{this.state.regions[5]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 7)} style={{backgroundImage: "url('images/regions/namangan.jpg')"}}>
								<span>{this.state.regions[6]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 8)} style={{backgroundImage: "url('images/regions/samarqand.jpg')"}}>
								<span>{this.state.regions[7]}</span>
							</div>
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 9)} style={{backgroundImage: "url('images/regions/sirdaryo.jpg')"}}>
								<span>{this.state.regions[8]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 10)} style={{backgroundImage: "url('images/regions/surxondaryo.jpg')"}}>
								<span>{this.state.regions[9]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 11)} style={{backgroundImage: "url('images/regions/toshkentvil.jpg')"}}>
								<span>{this.state.regions[10]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 12)} style={{backgroundImage: "url('images/regions/fargona.jpg')"}}>
								<span>{this.state.regions[11]}</span>
							</div>
						</div>
					</div>
					<div className="row mb-3 text-center">
						<div className="col-md-3"/>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 13)} style={{backgroundImage: "url('images/regions/xorazm.jpg')"}}>
								<span>{this.state.regions[12]}</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="regionBox" onClick={this.openOccupationMonthVideoPage.bind(this, 14)} style={{backgroundImage: "url('images/regions/toshkent.jpg')"}}>
								<span>{this.state.regions[13]}</span>
							</div>
						</div>
						<div className="col-md-3"/>
					</div>
				</div>
			</div>
		)
	}
}

export default OccupationMonthView;